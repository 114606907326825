import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.3.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SignUp from './userLogin/SignUp';
import SignIn from './userLogin/SignIn';
import MyContents from './myContents/MyContents';
import SendOffer from './offers/SendOffer';
import Offers from './offers/Offers';
import CheckOfferState from './offers/CheckOfferState';
import Diffusions from './diffusions/Diffusions';
import CheckViewership from './diffusions/CheckViewership';
import CheckDiffusionState from './diffusions/CheckDiffusionState';
import Revenu from './revenu/Revenu';
import Error1 from './errorManip/Error1';
import Error3 from './errorManip/Error3';
import useUser from './userLogin/useUser';
import { useTranslation } from 'react-i18next';

function App() {

	const {user, setUser} = useUser();

  	const { t } = useTranslation();

  	if(!user){
		return(
			<div className="App">
    	    	<Routes>
					<Route path="*" element={<SignIn setUser={setUser} translator={ t } />} />
					<Route path="/sign-up" element={<SignUp setUser={setUser} translator={ t } />} />
    	    	</Routes>
    		</div>
		) 
  	}

	try {
		const n7sdkInstance = window.N7SDK;
		const n7sdk = new n7sdkInstance();
		n7sdk.CoUserAuth.userSession();
	} catch(error){
		if(error.message==="expired"){
			sessionStorage.removeItem("user");
        	window.location.reload();
		}
	}

	const url = window.location.pathname;
    const urlArray = url.split("/");
    const coHash = urlArray[1];
  	return (
    	<div className="App">
    	    <Routes>
				<Route path="/:clientHashId" element={<MyContents translator={t} coHash={coHash} />} />
				<Route path="/:clientHashId/myContents" element={<MyContents translator={t} coHash={coHash} />} />
				<Route path="/:clientHashId/myContents/:contentid/sendOffer" element={<SendOffer translator={t} filters={[]} coHash={coHash} />} />
				<Route path="/:clientHashId/offers/:coOrSpOffer/:negotiationid/checkOffer" element={<CheckOfferState translator={t} filters={[]} coHash={coHash} />} />
				<Route path="/:clientHashId/offers" element={<Offers translator={t} filters={["cp", "state"]} coHash={coHash} />} />
				<Route path="/:clientHashId/diffusions" element={<Diffusions translator={t} filters={["cp", "sp"]} coHash={coHash} />} />
				<Route path="/:clientHashId/diffusions/:contentid/viewership" element={<CheckViewership translator={t} filters={[]} coHash={coHash} />} />
				<Route path="/:clientHashId/diffusions/:contentstatespid/checkInfo" element={<CheckDiffusionState translator={t} filters={[]} coHash={coHash} />} />
				<Route path="/:clientHashId/revenu" element={<Revenu translator={t} filters={["cp", "sp"]} coHash={coHash} />} />
				<Route path="*" element={<Error1 translator={t} />} />
				<Route path="/:clientHashId/bcError" element={<Error3 translator={t}/>} />
    	    </Routes>
    	</div>
  	);
}

export default App;