import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import { getCoOffer, getAllCoOffers, getAllSpOffers, getCpList } from './OfferManip';
import { hoverSubmitHandler, unhoverSubmitHandler } from '../components/ButtonManip';
import { showInfoPopUp, hideInfoPopUp } from '../components/PopUpManip';
import { calculateContainerHeight, calculateTableWidth } from '../components/ContainerDimensionManip';
import NoContent from '../components/NoContent';
import LoadingPage from '../components/LoadingPage';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';
import Error1 from '../errorManip/Error1';
import { getCoByHash } from '../userLogin/UserManip';

const Offers = ({ translator, filters, coHash }) => {
    const [offers, setOffers] = useState(null);
    const [deco, setDeco] = useState(null);
    const [cps, setCps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableStateWidth, setTableStateWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [coNotExistError, setCoNotExistError] = useState(false);

    const tagContainerStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "info-tag table-state";

            case "accepted" : 
                return "valid-tag table-state"

            case "sp_accepted":
                return "valid-tag table-state"
        
            default:
                return "info-tag table-state";
        }
    }

    const tagTextStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "info-tag-text"

            case "accepted" : 
                return "valid-tag-text"

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const completeOfferArray = (offerArray, coOrSpOffer) => {
        for (let i = 0; i < offerArray.length; i++) {
            var offer = offerArray[i];
            const coOfferInfoArray = [
                {
                    label: "identifiant",
                    value: offer.content_unique_id
                }
            ]
    
            const spOfferInfoArray = [
                {
                    label: "identifiant",
                    value: offer.content_unique_id
                }
            ]
            offer = {
                ...offer,
                coOrSpOffer: coOrSpOffer,
                info: coOrSpOffer==="co" ? coOfferInfoArray : spOfferInfoArray
            }
            
            offerArray[i] = offer;
        }
        return offerArray;
    }

    const removeSameOffer = (coOfferArray, spOfferArray) => {
        var negotiationContinueOn = [];
        for (let i = 0; i < spOfferArray.length; i++) {
            const offer = spOfferArray[i];
            negotiationContinueOn.push(offer.succession_negotiation);
        }

        let newCoOfferArray = coOfferArray;

        for (let i = 0; i < negotiationContinueOn.length; i++) {
            const negotiationId = negotiationContinueOn[i];
            newCoOfferArray = newCoOfferArray.filter((offer) => offer.negotiation_id!==negotiationId);
        }

        return newCoOfferArray;
    }

    const setCpFilter = (offers, cpList) => {
        var selectedCp = sessionStorage.getItem("offerCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            offers = offers.filter((offer) => cpList.find(cp => cp.cp_id===offer.content_provider).cp_name===selectedCp);
        }
        return offers;
    }

    const getCorrespondStateFilterId = (state, coOrSpOffer) => {
        var stateNotNull = state===null ? (coOrSpOffer==="co" ? "co_pending" : "sp_pending") : state;
        switch (stateNotNull) {
            case "co_pending":
                return "waiting_for_cp_reply";

            case "sp_pending":
                return "sp_negotiating"
        
            default:
                return state;
        }
    }

    const completeOfferArrayForDateComparison = async (offers) => {
        for (let i = 0; i < offers.length; i++) {
            var offer = offers[i];
            var compareDate = offer.received_time_cp_co;
            if(offer.coOrSpOffer==="sp"){
                const successionNegotiation = await getCoOffer(offer.succession_negotiation).then(res => res.data[0]);
                compareDate = successionNegotiation.received_time_cp_co;
            }

            offer = {
                ...offer,
                compareDate: compareDate
            }
            offers[i] = offer;
        }
        return offers
    }

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            var coOffers = await getAllCoOffers(coHash).then((res) => res.data);
            var spOffers = await getAllSpOffers(coHash).then((res) => res.data);
            var cpList = await getCpList().then((res) => res.data);
            setCps(cpList);

            // coOffers = removeNegotiationOffer(coOffers);
            coOffers = removeSameOffer(coOffers, spOffers);
            coOffers = completeOfferArray(coOffers, "co");
            // spOffers = removeNegotiationOffer(spOffers);
            spOffers = completeOfferArray(spOffers, "sp");
            var offers = [...coOffers, ...spOffers];
            offers = setCpFilter(offers, cpList);
            var selectedState = sessionStorage.getItem("offerStateFilter");
            if(selectedState!==null && selectedState!=="all"){
                offers = offers.filter((offer) => getCorrespondStateFilterId(offer.negotiation_valid, offer.coOrSpOffer)===selectedState);
            }
            offers = await completeOfferArrayForDateComparison(offers);

            offers.sort((offerA, offerB) => {
                var dateA = offerA.compareDate;
                var dateB = offerB.compareDate;
                return new Date(dateB) - new Date(dateA);
            });

            setOffers(offers);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name") === 0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableStateWidthValue = calculateTableWidth("table-state") === 0 ? "auto" : calculateTableWidth("table-state") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp") === 0 ? "auto" : calculateTableWidth("table-cp") + "px";

            if (contentHeightValue !== contentHeight || tableContentNameWidthValue !== tableContentNameWidth || tableCpWidthValue !== tableCpWidth || tableStateWidthValue !== tableStateWidth) {
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableStateWidth(tableStateWidthValue);
                setTableCpWidth(tableCpWidthValue);
            }
        };

        init();
    }, []);

    if(offers !== null){
        if(coNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(offers.length!==0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cps={cps} 
                                />
                                <div className="table-container" style={{maxHeight: contentHeight}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{color: deco.secondaryColor, width: tableContentNameWidth, wordWrap: "normal"}}><p style={{margin:0}}>{translator("content_name")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableStateWidth}}><p style={{margin: 0, width: "max-content"}}>{translator("negotiation_state")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableCpWidth}}><p style={{margin: 0, width: "max-content"}}>{translator("content_provider")}</p></th>
                                                <th style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                                <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {offers.map((offer, id) => 
                                                <tr key = {id} >
                                                    <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{margin:0, width: "max-content"}}>{offer.content_name}</p></td>
                                                    <td>
                                                        <div className={tagContainerStyleHandler(offer.negotiation_valid)}>
                                                            <div className={tagTextStyleHandler(offer.negotiation_valid)}>
                                                                {offer.negotiation_valid===null ? ( offer.coOrSpOffer==="co" ? translator("waiting_for_cp_reply") : translator("sp_negotiating")) : translator(offer.negotiation_valid)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-cp" style={{margin: 0, width: "max-content"}}>{cps.find(cp => offer.content_provider===cp.cp_id).cp_name}</p></td>
                                                    <td>
                                                        <Link to={"/" + coHash + "/offers/" + offer.coOrSpOffer + "/" + offer.negotiation_id + "/checkOffer"}>
                                                            <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("check_proposal")} style={{color: deco.secondaryColor}}/>
                                                        </Link>
                                                    </td>
                                                    <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                        <span id={"info-pop-up-container-" + id} style={{visibility: "hidden"}}><InfoPopUp infos={offer.info} /></span>
                                                        <img alt="info-pop-up-icon" className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(offers.length===0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cps={cps} 
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            }
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Offers;

// const tagContainerStyleHandler = (valid) => {
//     switch (valid) {
//         case "":
//             return "info-tag table-state";

//         case "rejected" : 
//             return "attention-tag table-state"

//         case "negotiating" : 
//             return "attention-tag table-state"

//         case "accepted" : 
//             return "valid-tag table-state"

//         case "sp_negotiating":
//             return "info-tag table-state"

//         case "sp_accepted":
//             return "valid-tag table-state"
    
//         default:
//             return "info-tag table-state";
//     }
// }

// const tagTextStyleHandler = (valid) => {
//     switch (valid) {
//         case "":
//             return "info-tag-text"
    
//         case "rejected" : 
//             return "attention-tag-text"

//         case "negotiating" : 
//             return "attention-tag-text"

//         case "accepted" : 
//             return "valid-tag-text"

//         case "sp_negotiating":
//             return "info-tag-text"

//         case "sp_accepted":
//             return "valid-tag-text"
    
//         default:
//             return "info-tag-text";
//     }
// }

// const removeNegotiationOffer = (offerArray) => {
//     var negotiateOn = [];
//     for (let i = 0; i < offerArray.length; i++) {
//         const offer = offerArray[i];
//         if(offer.negotiate_on !== null){
//             negotiateOn.push(offer.negotiate_on);
//         }
//     }

//     let newOfferArray = offerArray;

//     for (let i = 0; i < negotiateOn.length; i++) {
//         const offerId = negotiateOn[i];
//         newOfferArray = newOfferArray.filter((offer) => offer.negotiation_id!==offerId);
//     }
//     return newOfferArray;
// }