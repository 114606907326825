import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
.use(initReactI18next)
.use(LanguageDetector)
.init(
    {
        debug: true,
        fallbackLng: 'fr',
        resources: {
            fr: {
                translation: {
                    //login
                    admin_login: "Accès Admin",
                    company_name: "Société",
                    your_company_name: "Nom de votre société",
                    user_name: "Identifiant",
                    your_user_name: "Votre identifiant",
                    pwd: "Mot de passe",
                    your_pwd: "Votre mot de passe",
                    email: "Email",
                    your_email: "Votre email",
                    sign_in: "S'identifier",
                    sign_up: "Créer un compte",
                    send_verification_code: "Envoyer le code de vérification",
                    enter_company_name_msg: "Veilliez saisir le nom de votre société",
                    enter_user_name_msg: "Veilliez saisir votre identifiant!",
                    enter_user_pwd_msg: "Veilliez saisir votre mot de passe!",
                    enter_user_email: "Veilliez saisir votre email!",
                    wrong_company_name_or_wrong_user_name: "Mauvais Nom de Société ou Mauvais Identifiant",
                    wrong_user_name_or_wrong_pwd: "Mauvais identifiant ou mauvais mot de passe",
                    wrong_pwd: "Mauvais Mot de Passe",
                    verification_code: "Code de vérification",
                    //foot
                    about_n7_project: "À PROPOS DU PROJET N7",
                    contact_n7: "CONTACTER N7",
                    made_by_n7_development_team: "réalisé par l'équipe de développement de N7",
                    //loading 
                    loading: "Chargement en cours",
                    waiting_for_bc_response: "Nous attendons une réponse",
                    bc_msg: "La blockchain permet de sécuriser et fiabiliser la transmission des données et requiert un cours instant pour le réaliser.",
                    //errors
                    no_content: "Pas de données à afficher",
                    no_content_with_filter: "Il n’existe pas de données qui correspondent au filtre sélectionné",
                    no_content_without_filter: "Il n'y a pas de données à afficher.",
                    error_404: "Erreur 404",
                    page_not_found: "Page non Trouvée",
                    retry: "Nous vous invitons à ressayer votre action, en cas de nouvelle erreur, nous vous invitons à ressayer plus tard.",
                    bc_error: "Une erreur est survenue sur la blockchain.",
                    //navbar
                    my_contents: "Mes Contenus",
                    offers: "Offres",
                    diffusions: "Diffusions",
                    state: "État",
                    //content table
                    information: "Infos",
                    //users
                    content_owner: "CO",
                    content_owner_full: "Content Owner",
                    content_provider: "CP",
                    content_provider_full : "Content Provider",
                    service_provider : "SP",
                    service_provider_full: "Service Provider",
                    no_viewer: "Aucun Viewer",
                    viewer: "Viewer",
                    viewers: "Viewers",
                    viewer_s: "Viewer(s)",
                    //content
                    no_local_content: "Aucun Contenu",
                    local_contents: "Contenus Locaux",
                    local_content_s: "Contenu(s) Local/Locaux",
                    co_contents: "Tous les Contenus",
                    no_co_content: "Aucun Contenu",
                    co_content_s: "Contenu(s)",
                    no_possible_content: "Aucun Contenu",
                    possible_contents: "Disponibles N7",
                    possible_content_s: "Disponible(s) N7",
                    no_available_content: "Aucun Contenu",
                    available_contents: "Négociés CP",
                    available_content_s: "Négocié(s) CP",
                    no_supplied_content: "Aucun Contenu en Cours de Distribution",
                    supplied_contents: "En Cours de Distribution",
                    supplied_content_s: "En Cours de Distribution",
                    no_benefits: "Aucune Prestation",
                    //content info
                    content_size: "Taille",
                    content_id: "Identifiant du Contenu",
                    content_name: "Nom du Contenu",
                    upload_date: "Date d'Ajout",
                    content_state: "État du Contenu",
                    content_type: "Type du Contenu",
                    ledger_content_info: "Information du Contenu sur Le Registre Distribué",
                    timestamp: "Horodatage",
                    viewership: "Consommations de Contenu",
                    proof: "Preuve",
                    no_proof: "Aucune Preuve",
                    proofs: "Preuves",
                    proof_s: "Preuve(s)",
                    //content state
                    all: "Tous",
                    co_local_content: "Contenu Local du CO",                    
                    waiting_for_co_proposal: "En Attente de Création de l'offre",
                    under_cp_co_negotiation: "En Cours de Négociation CP",
                    available: "Disponible",
                    under_cp_sp_negotiation: "En Cours de Négociation SP",
                    supplied: "En Cours de distribution",
                    contentadded: "Contenu est enregistré sur la blockchain",
                    cpallowed: "CP a le droit de distribuer.",
                    diffusing: "En Cours de distribution",
                    verifying: "En Cours d'Évaluation",
                    proof_not_verified : "Preuve Non Vérifiée",
                    proof_verified: "Preuve Vérifiée",
                    added_time: "Date d'ajout du contenu",
                    send_time_co_cp: "Date d'envoie d'offre CO",
                    valid_time_co_cp: "Date de validation d'offre CO",
                    send_time_cp_sp: "Date d'envoie d'offre SP",
                    valid_time_cp_sp: "Date de validation d'offre SP",
                    //negotiation
                    no_negotiation: "Aucune Négociation",
                    negotiation: "Négociation",
                    negotiations: "Négociations",
                    negotiation_s: "Négociation(s)",
                    negotiation_list: "Liste de Négociations",
                    //proposal
                        //proposal state
                    proposal_accepted: "Proposition Acceptée",
                    proposal_rejected: "Proposition Rejetée",
                    offer_cancelled: "L'offre est annulé",
                    negotiating_proposal: "Offre sous Négotiation",
                                //proposal info
                    offer : "Offre",
                    cp_reward: "Marge Aval",
                    content_price: "Prix de Vente",
                    volume_commitment: "Engagement de Volume",
                    availability_start_date: "Date du Début d'Accès",
                    availability_end_date: "Date de la Fin d'Accès",
                    access_duration: "Durée d'Accès",
                    service_start_date: "Date du Début du Service",
                    service_end_date: "Date de la Fin du Service",
                    service_dates: "Dates de Service",
                    service_price: "Prix de Service",
                    delivery_duration: "Durée de Distribution",
                    broadcasting_dates: "Dates Diffusion",
                    year_s: "année(s)",
                    month_s: "mois",
                    day_s: "jour(s)",
                    message: 'Message', 
                    views: "Vues",  
                    income: "Revenu",
                    rejection_msg: "Motif de Refus",
                    negotiation_msg: "Commentaire de la Négociation",
                            //reply
                    response: "Réponse",
                    response_from_the_content_provider: "Réponse du CP",
                                //reply state
                    no_reply_for_this_proposal: "Aucun Réponse pour ce Contenu",
                    accepted: "Offre CP Acceptée",
                    rejected: "Offre CP Rejetée",
                    negotiating: "Offre CP Negociée",
                    waiting_for_cp_reply: "Offre CP en Attente",
                    sp_negotiating: "Offre SP en attente",
                    sp_accepted: "Offre SP Acceptée",
                    //actions
                        //general
                    close: "Fermer",
                    upload: "Téléverser",
                    action: "Action",
                    actions: "Actions",
                    submit: "Envoyer",
                    delete: "Supprimer",
                    back_to_page: "retour",
                        //local content   
                    check_content: "Voir",                 
                    upload_local_content: "Téléverser votre contenu",
                    drag_and_drop_or: "Glisser et déposer ou ",
                    browse_file: "Parcourir le dossier",
                        //negotiation
                    accept: "Accepter",
                    negotiation_state: "État des Offres",
                    accept_proposal: "Accepter la Proposition",
                    reject: "Rejeter",
                    cancel_offer: "Annuler l'Offre",
                    delete_offer: "Supprimer l'Offre",
                    reject_proposal: "Rejeter la Proposition",
                    negotiate: "Négocier",
                    offer_information: "Informations de l'Offre",
                        //proposal
                    send_proposal: "Créer une Offre",
                    check_proposal: "Accéder à l'Offre",
                    get_cp_co_negotiation_list: "Voir la/les Négociation(s)",
                        //reply
                    check_cp_response: "Voir la Réponse",
                    validate: "Valider",
                    validate_proposal: "Valider l'Offre",
                    check_details: "Voir Détails",
                    cancel: "Annuler",
                        //available content
                    check_content_on_distributed_ledger: "Vérifier le Contenu sur le Registre Distribué",
                        //supplied content
                    check_viewership: "Accéder à la Consommation",
                    check_proof: "Vérifier la Preuve",
                    check_benefits: "Revenus",
                    //action feedback
                    action_failed: "Action Échouée",
                    field_not_null: "Ce champ est obligatoire.",
                    enter_a_number: "Entrer un chiffre.",
                        //local content
                    content_added: "Contenu Ajouté",
                    added: "Ajouté",
                    add_content_failed: "Ajout du Contenu Échoué",
                    no_such_preview: "Aucun Aperçu",
                    content_uploaded: "Disponibles dans N7",
                    upload_content_failed: "Téléversement dans N7 Échoué",
                        //previews
                    no_proposal_from_content_provider_yet: "Aucun Propositions Pour le Moment",
                        //negotiation
                    proposal_sent: "L'offre a bien été envoyé au(x) CP(s) choisi(s)",
                    cp_reward_between: "La marge aval est compris entre",
                    and: "et",
                    that_is: "Cela fait",
                    for_cp: "pour le CP et",
                    for_co: "pour",
                    send_proposal_failed: "Envoi de l'Offre Échoué",
                    enter_correct_availability_start_date_msg: "Veuillez entrer une date de début correcte!",
                    enter_correct_availability_end_date_msg: "La date de fin d'accès doit être postérieure à la date de début d'accès!", 
                        //reply
                    content_uploaded_failed: "Le téléversement du contenu échoué",
                    content_deleted: "Le contenu a été supprimé",
                    content_deleted_failed: "La suppression du contenu échouée",
                    negotiation_accepted: "La négotiation est acceptée.",
                    negotiation_cancelled: "La négotiation est rejetée.",
                    rejected_proposal_deleted: "La proposition rejetée est supprimée.",
                    valid_proof: "Preuve Validée",
                    fake_proof: "Fausse Preuve",
                    cp_verifying: "En cours de Vérification",
                    loading: "Chargement...",
                }
            },
            en: {
                translation: {
                    //login
                    admin_login: "Admin Login",
                    company_name: "Company",
                    your_company_name: "Your company name",
                    user_name: "User Name",
                    your_user_name: "Your user name",
                    pwd: "Password",
                    your_pwd: "Your password",
                    email: "Email",
                    your_email: "Your email",
                    sign_in: "Sign In",
                    sign_up: "Sign Up",
                    send_verification_code: "Send verification code",
                    enter_company_name_msg: "Pleasen enter your company name!",
                    enter_user_name_msg: "Please, enter your user name!",
                    enter_user_pwd_msg: "Please, enter your password!",
                    enter_user_email: "Please, enter your email",
                    wrong_company_name_or_wrong_user_name: "Wrong Company Name or Wrong User Name",
                    wrong_user_name_or_wrong_pwd: "Wrong user name or wrong password",
                    wrong_pwd: "Wrong Password",
                    verification_code: "Verification code",
                    //foot
                    about_n7_project: "ABOUT N7 PROJECT",
                    contact_n7: "CONTACT N7",
                    made_by_n7_development_team: "made by N7 development team",
                    //loading
                    loading: "Loading in progress",
                    waiting_for_bc_response: "We are waiting for answers from the Blockchain",
                    bc_msg: "Blockchain enables data to be transmitted securely and reliably, and requires a short time to do so.",
                    //errors
                    no_content: "No data to display",
                    no_content_with_filter: "There are no data that correspond to the selected filter.",
                    no_content_without_filter: "There are no data to display.",
                    error_404: "404 Error",
                    page_not_found: "Page not found",
                    retry: "We invite you to try your action again, if the error persists, we invite you to try again later.",
                    bc_error: "An error has occurred on the blockchain network",
                    //navbar
                    my_contents: "My Contents",
                    offers: "Offers",
                    diffusions: "Broadcast",
                    state: "State",
                    //content table
                    information: "Information",
                    //users
                    content_owner: "CO",
                    content_provider: "CP",
                    service_provider: "SP",
                    content_owner_full: "Content Owner",
                    content_provider_full: "Content Provider",
                    service_provider_full: "Service Provider",
                    viewer: "Viewer",
                    no_viewer: "No Viewer",
                    viewers: "Viewers",
                    viewer_s: "Viewer(s)",
                    //content
                    local_contents: "Local Contents",
                    local_content_s: "Local Content(s)",
                    co_contents: "All the Contents",
                    co_content_s: "Content(s)",
                    no_possible_content: "No Uploaded Content",
                    possible_contents: "Uploaded Contents",
                    possible_content_s: "Uploaded Content(s)",
                    no_available_content: "No Available Content",
                    available_contents: "Available Contents",
                    available_content_s: "Available Content(s)",
                    no_supplied_content: "No Supplied Content",
                    supplied_contents: "Supplied Contents",
                    supplied_content_s: "Supplied Content(s)",
                    no_benefits: "No Benefits",
                    //content info
                    content_size: "Size",
                    content_id: "Content ID",
                    content_name: "Content Name",
                    upload_date: "Date of Upload",
                    content_state: "Content State",
                    content_type: "Content Type",
                    ledger_content_info : "Information of the Content on Distributed Ledger",
                    timestamp: "Timestamp",
                    viewership: "Viewership",
                    proof: "Proof",
                    no_proof: "No Proof",
                    proofs: "Proofs",
                    proof_s: "Proof(s)",
                    //content state
                    all: "All",
                    co_local_content: "CO's Local Content",
                    waiting_for_co_proposal: "Waiting For CO's Proposals",
                    under_cp_co_negotiation: "Under CP-CO Negotiations",
                    available: "Available",
                    under_cp_sp_negotiation: "Under CP-SP Negotiations",
                    supplied: "Supplied",
                    contentadded: "Content is uploaded to the blockchain",
                    cpallowed: "CP has the right to deliver the content.",
                    diffusing: "Diffusing",
                    verifying: "Verifying",
                    proof_not_verified: "Proof not Verified",
                    proof_verified: "Proof Verified",
                    added_time: "Date of content upload",
                    send_time_co_cp: "Send date of CO offer",
                    valid_time_co_cp: "Date of validation of CO offer",
                    send_time_cp_sp: "Send date of SP offer",
                    valid_time_cp_sp: "Date of validation of SP offer",
                    //negotiation
                    negotiation_state: "Offer State",
                    no_negotiation: "No Negotiation",
                    negotiation: "Negotiation",
                    negotiations: "Negotiations",
                    negotiation_s: "Negotiation(s)",
                    negotiation_list: "Negotiation List",
                    //proposal
                        //proposal state
                    proposal_accepted: "Proposition Accepted",
                    proposal_rejected: "Proposition Rejected",
                    offer_cancelled: "The offre is cancelled",
                    negotiating_proposal: "Offre sous Négotiation",
                        //proposal info
                    offer : "Offer",
                    content_price: "Content Price",
                    cp_reward: "CP's Reward",
                    volume_commitment: "Volume Commitment",
                    availability_start_date: "Availability Start Date",
                    availability_end_date: "Availability End Date",
                    access_duration: "Access Duration",
                    service_start_date: "Service Start Date",
                    service_end_date: "Service End Date",
                    delivery_duration: "Delivery Duration",
                    broadcasting_dates: "Broadcasting Dates",
                    year_s: "year(s)",
                    month_s: "month(s)",
                    day_s: "day(s)",
                    message: 'Message',
                    income: 'Income',
                    views: "Views",
                    rejection_msg: "Reason for refusal",
                    negotiation_msg: "Negotiation Commentary",
                            //reply
                    response: "Response",
                    response_from_the_content_provider: "Reply from the CP",
                                //reply state
                    no_reply_for_this_proposal: "No Reply for this Offer",
                    accepted: "CP Offer Accepted",
                    rejected: "CP Offer Rejected",
                    negotiating: "CP Offer Negotiating",
                    waiting_for_cp_reply: "Waiting for CP Reply",
                    sp_negotiating: "SP Offer Negotiating",
                    sp_accepted: "SP Offer Accepted",
                    //actions
                        //general
                    action: "Action",
                    actions: "Actions",
                    close: "Close",
                    upload: "Upload",
                    submit: "Submit",
                    delete: "Delete",
                    back_to_page: "return",
                        //local content
                    check_content: "Check",
                    upload_local_content: "Upload your content",
                    drag_and_drop_ou: "Drag and drop or ",
                    browse_file: "Browse file",
                        //negotiation
                    accept: "Accept",
                    accept_proposal: "Accept Proposal",    
                    reject: "Reject",
                    reject_proposal: "Reject Proposal",
                    negotiate: "Negotiate",
                    cancel_offer: "Cancel Offer",
                    delete_offer: "Delete Offer",
                    offer_information: "Offer Information",
                        //proposal
                    send_proposal: "Send Proposal",
                    check_proposal: "Check Proposal",
                    get_cp_co_negotiation_list: "Check the Negotiation(s)",
                        //reply
                    check_cp_response: "Check CP's Reply",
                    validate: "Validate",
                    validate_proposal: "Validate the Offer",
                    check_details: "Check Details",
                    cancel: "Cancel",
                        //available content
                    check_content_on_distributed_ledger: "Check the Content On Distributed Ledger",
                        //supplied content
                    check_viewership: "Check Viewership",
                    check_proof: "Check the Proof",
                    check_benefits: "Check Benefits",
                    //action feedback
                    action_failed: "Action Failed",
                    field_not_null: "This field is required.",
                    enter_a_number: "This is not a valid number",
                        //local content
                    content_added: "Content Added",
                    added: "Added",
                    add_content_failed: "Add Content Failed",
                    no_such_preview: "No Such Preview",
                    content_uploaded: "Content uploaded",
                    upload_content_failed: "Upload content failed",
                        //preview    
                    no_proposal_from_content_provider_yet: "No Proposal From CP Yet",
                        //negotiation action feedback
                    proposal_sent: "Proposal Sent",
                    cp_reward_between: "CP's reward is between",
                    and: "and",
                    that_is: "That is",
                    for_cp: "for the CP and",
                    for_co: "for",
                    send_proposal_failed: "Send Proposal Failed",
                    enter_correct_availability_start_date_msg: "Please fill in a correct availability start date!",
                    enter_correct_availability_end_date_msg: "The availability end date must be later than the availability start date!",
                        //reply
                    content_uploaded_failed: "The content cannot be uploaded to N7",
                    content_deleted: "The content is deleted",
                    content_deleted_failed: "The content cannot be deleted",
                    negotiation_accepted: "The negotiation is accepted.",
                    negotiation_cancelled: "The negotiation is refused.",
                    rejected_proposal_deleted: "The rejected negotiation is deleted.",
                    valid_proof: "Valid Proof",
                    fake_proof: "Fake Proof",
                    cp_verifying: "Waiting for CP's Verification",
                    loading: "Loading...",
                }
            }
        }
    }
)

export default i18next;