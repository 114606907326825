import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { Modal, Form as ModalForm, Row, Col } from 'react-bootstrap';
import "../components/UserLogin.css";
import { getCoList, getCoId, addNewCo } from "./UserManip";
import { addFirstLoginUserToHF } from "../blockchainManip/BCManip";
import LoginNavbar from "../components/LoginNavbar";
import LoadingButton from '../components/LoadingButton';
import { hoverSubmitHandler, unhoverSubmitHandler } from "../components/ButtonManip";
import PropTypes from "prop-types";

const SignUp = ({translator}) => {

    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [cos, setCos] = useState([]);
    const [coName, setCoName] = useState("");
    const [coUserName, setCoUserName] = useState("");
    const [coUserPwd, setCoUserPwd] = useState("");
    const [coUserEmail, setCoUserEmail] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [showCodeBox, setShowCodeBox] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const form = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const cos = await getCoList().then((res) => res.data);
            var coOptions = [];
            for (let i = 0; i < cos.length; i++) {
                const co = cos[i].co_name;
                var coOption = {
                    value: co,
                    label: co
                }
                coOptions.push(coOption);
            }
            setCos(coOptions);

            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.CoUserAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const onFinish = async () => {
        setShowLoader(true);
        try {
            cognitoAuthSDK.registerUser(coUserName, coUserPwd, coUserEmail).then(res => {
                setShowLoader(true);
                setShowCodeBox(true);
            }).catch(err => {
                alert(err);
                window.location.reload();
            })
        } catch(error){
            console.error(error);
        }
    }

    const coNameHandler = (value) => {
        setCoName(value);
    }

    const coNameFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

    const coUserNameHandler = (event) => {
        setCoUserName(event.target.value);
    }
    
    const coUserPwdHandler = (event) => {
        setCoUserPwd(event.target.value);
    }

    const coUserEmailHandler = (event) => {
        setCoUserEmail(event.target.value);
    }

    const formVerification = () => {
        const companyNotNull = coName!=="";
        const userNameNotNull = coUserName!=="";
        const userPwdNotNull = coUserPwd!=="";
        const userEmailNotNull = coUserEmail!=="";
        const userEmailFormatcorrect = validEmailAddress(coUserEmail);
        if(companyNotNull&&userNameNotNull&&userPwdNotNull&&userEmailNotNull&&userEmailFormatcorrect){
            return(
                <div className="login-submit-btn">
                    <LoadingButton 
                        text={translator('send_verification_code')} 
                        className="n7-button normal-button" 
                        onSubmit={onFinish} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    />
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            )
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("send_verification_code")}</button>
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            )
        }
    }

    const validEmailAddress = (userMail) => {
        const format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return format.test(String(userMail).toLowerCase())
    }

    const hideCodeBox = () => {
        setShowCodeBox(false);
    }

    const verificationCodeHandler = (event) => {
        setVerificationCode(event.target.value);
    }

    const confirmation = async () => {
        setShowModalLoader(true);
        cognitoAuthSDK.confirmeUser(coUserName, verificationCode).then(async () => {
            const coId = await getCoId(coName).then(res => res.data[0].co_id);
            let user = {
                coCompany: coId,
                coUserName: coUserName,
                coUserPwd: coUserPwd
            }
            addNewCo(user).then(() => {
                cognitoAuthSDK.login(coUserName, coUserPwd, (error, token) => {
                    let user = {
                        username: coUserName
                    }
                    addFirstLoginUserToHF(user, token).then(() => {
                        window.location.assign("/");
                    }).catch(err => {
                        alert(err);
                    });
                })
            }).catch(err => {
                alert(err);
            })
        }).catch(err => {
            alert(err);
            setShowModalLoader(false);
        })
    }

    if(cos !== null){
        return (
            <div className="wrapper">
                <div className="navbar">
                    <LoginNavbar />
                </div>
                <div className="login-container">
                    <div className="login-form-container">
                        <div className="login-input-container">
                            <Form 
                                name="normal_login"
                                layout="vertical"
                                className="login-inputs"
                            >
                                <Form.Item
                                    name="content_owner_name"
                                    label={translator("company_name")}
                                    rules={[{
                                        required: true,
                                        message: translator("enter_company_name_msg")
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={translator('your_company_name')}
                                        onChange={coNameHandler}
                                        filterOption={coNameFilterOption}
                                        options={cos}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="content_owner_user_name"
                                    label={translator("user_name")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_name_msg')
                                    }]}>
                                    <Input  
                                        placeholder={ translator('your_user_name') } 
                                        autoComplete="off"
                                        value={coUserName}
                                        onChange={coUserNameHandler}/>
                                </Form.Item>
                                <Form.Item
                                    name="content_owner_user_pwd"
                                    label={translator("pwd")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_pwd_msg')
                                    }]}>
                                    <Input.Password
                                        type="password"
                                        placeholder={translator('your_pwd')}
                                        autoComplete="off"
                                        value={coUserPwd}
                                        onChange={coUserPwdHandler}/>
                                </Form.Item>  
                                <Form.Item
                                    name="content_owner_user_email"
                                    label={translator("email")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_email')
                                    }]}>
                                    <Input
                                        style={{ width: "300px" }}
                                        placeholder={translator('your_email')}
                                        autoComplete="off"
                                        value={coUserEmail}
                                        onChange={coUserEmailHandler}/>
                                </Form.Item>  
                            </Form>
                        </div>
                        {formVerification()}
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-left-panel">
                        <div className="footer-left-panel-about-n7">
                            <a id="about-n7-link" href="#">
                                {translator('about_n7_project')}
                            </a>
                        </div>
                        <div className="footer-left-panel-contact">
                            <a id="contact-link" href="#">
                                {translator('contact_n7')}
                            </a>
                        </div>
                    </div>
                    <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
                </div>
                <Modal show={showCodeBox} onHide={hideCodeBox} style={{ position: "fixed", zIndex: "9999" }}>
                    <Modal.Header>
                        <Modal.Title>
                            {translator('verification_code')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-confirmation">
                        <ModalForm ref={form}>
                            <ModalForm.Group as={Row} className="mb-3">
                                <Col>
                                    <ModalForm.Control type="text" required onChange={event=>{verificationCodeHandler(event)}} />
                                </Col>
                            </ModalForm.Group>
                        </ModalForm>
                    </Modal.Body>
                    <Modal.Footer>
                        { verificationCode=="" ? 
                            <button className="n7-button normal-button disable-button" disabled >{translator("validate")}</button> : 
                            <LoadingButton 
                                text={translator("validate")} 
                                onSubmit={confirmation} 
                                loading={showModalLoader} 
                                disabled={showModalLoader} 
                            /> 
                        }
                        </Modal.Footer>
                </Modal>
            </div>
        )
    }   
}

SignUp.propTypes = {
    setUser: PropTypes.func.isRequired,
};

export default SignUp;
