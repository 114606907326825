import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import { getAllDiffusion, getSpList } from '../myContents/ContentManip';
import { hoverSubmitHandler, unhoverSubmitHandler } from '../components/ButtonManip';
import { showInfoPopUp, hideInfoPopUp } from '../components/PopUpManip';
import { getCpList } from '../offers/OfferManip';
import { calculateContainerHeight, calculateTableWidth } from '../components/ContainerDimensionManip';
import NoContent from '../components/NoContent';
import LoadingPage from '../components/LoadingPage';
import { getCoByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';
import Error1 from '../errorManip/Error1';

const Diffusions = ({ translator, filters, coHash }) => {
    const [diffusions, setDiffusions] = useState(null);
    const [deco, setDeco] = useState(null);
    const [cps, setCps] = useState([]);
    const [sps, setSps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [tableSpWidth, setTableSpWidth] = useState("");
    const [tableDatesWidth, setTableDatesWidth] = useState("");
    const [coNotExistError, setCoNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            const cpList = await getCpList().then(res => res.data);
            const spList = await getSpList().then(res => res.data);
            setCps(cpList);
            setSps(spList);

            var diffusionsData = await getAllDiffusion(coHash).then(res => res.data);
            diffusionsData = completeDiffusionArray(diffusionsData);
            diffusionsData = setCpFilter(diffusionsData, cpList);
            diffusionsData = setSpFilter(diffusionsData, spList);
            diffusionsData = await completeOfferArrayForDateComparison(diffusionsData);
            setDiffusions(diffusionsData);

            const contentHeigthValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp")===0 ? "auto" : calculateTableWidth("table-cp") + "px";
            const tableSpWidthValue = calculateTableWidth("table-sp")===0 ? "auto" : calculateTableWidth("table-sp") + "px";
            const tableDatesWidthValue = calculateTableWidth("table-dates")===0 ? "auto" : calculateTableWidth("table-dates") + "px";

            if (contentHeigthValue !== contentHeight || tableContentNameWidthValue !== tableContentNameWidth || tableCpWidthValue !== tableCpWidth || tableSpWidthValue !== tableSpWidth || tableDatesWidthValue != tableDatesWidth) {
                setContentHeight(contentHeigthValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCpWidth(tableCpWidthValue);
                setTableSpWidth(tableSpWidthValue);
                setTableDatesWidth(tableDatesWidthValue);
            }
        }

        init();
    }, []);

    const completeDiffusionArray = (diffusionArray) => {
        for (let i = 0; i < diffusionArray.length; i++) {
            var diffusion = diffusionArray[i];
            diffusion = {
                ...diffusion,
                info: [
                    {
                        label: "identifiant",
                        value: diffusion.content_unique_id
                    }
                ]
            }
            diffusionArray[i] = diffusion;
        }
        return diffusionArray;
    }

    const setCpFilter = (diffusions, cpList) => {
        var selectedCp = sessionStorage.getItem("diffusionCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            diffusions = diffusions.filter((diffusion) => cpList.find(cp => cp.cp_id===diffusion.content_provider).cp_name===selectedCp);
        }
        return diffusions;
    }

    const setSpFilter = (diffusions, spList) => {
        var selectedSp = sessionStorage.getItem("diffusionSpFilter");
        if(selectedSp!==null && selectedSp!=="all"){
            diffusions = diffusions.filter((diffusion) => spList.find(sp => sp.sp_id===diffusion.service_provider).sp_name===selectedSp);
        }
        return diffusions;
    }

    const completeOfferArrayForDateComparison = async (diffusions) => {
        return Promise.all(diffusions.map(async diffusion => {
            const compareDate = diffusion.valid_time_cp_sp;
            return {
                ...diffusion,
                compareDate: compareDate
            }
        }));
    }

    const diffusionAvailabilityTag = (serviceStartDate, serviceEndDate) => {
        var today = new Date();
        today = today.setHours(0,0,0,0);
        var diffusionStartDate = new Date(serviceStartDate);
        diffusionStartDate.setHours(0,0,0,0);
        var diffusionEndDate = new Date(serviceEndDate);
        diffusionEndDate.setHours(0,0,0,0);
        if(today < diffusionStartDate || today > diffusionEndDate){
            return (
                <div className="info-tag info-tag-text">
                    <p className="table-dates" style={{ margin: 0, width: "max-content" }}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        } else {
            return (
                <div className="valid-tag valid-tag-text">
                    <p className="table-dates" style={{ margin: 0, width: "max-content" }}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        }
    }

    if(diffusions !== null){
        if(coNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(diffusions.length!==0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={ translator }
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cps={cps} 
                                    sps={sps} 
                                />
                                <div className="table-container" style={{ maxHeight: contentHeight }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}><p style={{ margin: 0, wordWrap: "normal" }}>{translator("content_name")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableCpWidth}}><p style={{ margin: 0 }}>{translator("content_provider")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableSpWidth}}><p style={{ margin: 0 }}>{translator("service_provider")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableDatesWidth}}><p style={{ margin: 0 }}>{translator("broadcasting_dates")}</p></th>
                                                <th style={{color: deco.secondaryColor}}>{translator("action") }</th>      
                                                <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {diffusions.map((diffusion, id) => 
                                                <tr key = {id} >
                                                    <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{ margin: 0, width: "max-content" }}>{diffusion.content_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-cp" style={{ margin: 0, width: "max-content" }}>{cps.find(cp => cp.cp_id===diffusion.content_provider).cp_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-sp" style={{ margin: 0, width: "max-content" }}>{sps.find(sp => sp.sp_id===diffusion.service_provider).sp_name}</p></td>
                                                    <td style={{ paddingRight: "85px" }}> 
                                                        {diffusionAvailabilityTag(diffusion.service_start_date, diffusion.service_end_date)}
                                                    </td>
                                                    <td style={{ whiteSpace: "nowrap" }}>
                                                        <Link to={"/" + coHash + "/diffusions/" + diffusion.content_state_sp_id + "/viewership"}>
                                                            <input 
                                                                type="button"
                                                                className="table-action-buttons n7-button normal-button" 
                                                                onMouseEnter={e=>hoverSubmitHandler(e)}
                                                                onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                                value={translator("check_viewership")} 
                                                                style={{color: deco.secondaryColor}}
                                                            />
                                                        </Link>
                                                        <Link to={"/" + coHash + "/diffusions/" + diffusion.content_state_sp_id + "/checkInfo"}>
                                                            <input 
                                                                type="button"
                                                                className="table-action-buttons n7-button normal-button" 
                                                                onMouseEnter={e=>hoverSubmitHandler(e)}
                                                                onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                                value={translator("check_proposal")} 
                                                                style={{color: deco.secondaryColor}}
                                                            />
                                                        </Link>
                                                    </td>
                                                    <td className="table-infomation-panel" style={{ paddingRight: "15px", textAlign: "right"}}>
                                                        <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={diffusion.info} /> </span>
                                                        <img className="info-pop-up-img" onMouseEnter={() => {showInfoPopUp(id)}} onMouseLeave={() => {hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(diffusions.length===0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cps={cps} 
                                    sps={sps} 
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            }
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Diffusions;