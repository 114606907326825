import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import { getDiffusion } from '../myContents/ContentManip';
import { calculateContainerHeight } from '../components/ContainerDimensionManip';
import Error1 from '../errorManip/Error1';
import { getCoByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const CheckDiffusionState = ({translator, filters, coHash}) => {
    const [deco, setDeco] = useState(null);
    const [diffusionInfo, setDiffusionInfo] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [coNotExistError, setCoNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            const uri = window.location.pathname;
            const uriArray = uri.split("/");
            const contentStateSpId = uriArray[uriArray.length - 2];
            const diffusionInfo = await getDiffusion(coHash, contentStateSpId).then(res => res.data);
            setDiffusionInfo(diffusionInfo.length === 0 ? diffusionInfo : diffusionInfo[0]);

            const newContentHeight = calculateContainerHeight();
            if (newContentHeight !== contentHeight) {
                setContentHeight(newContentHeight);
            }
        };

        init();
    }, []);

    if (diffusionInfo !== null) {
        if (diffusionInfo.length===0 || coNotExistError) {
            return <Error1 translator={translator} />;
        } else {
            return (
                <div className="wrapper">
                    <Navbar
                        translator={translator}
                        deco={deco}
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar
                                translator={translator}
                                filters={filters}
                                deco={deco}
                                returnPath={"/" + coHash + "/diffusions"}
                                contentName={diffusionInfo.content_name}
                            />
                            <div className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                                <div className="form-horizon-sub-item">
                                                    <div className="form-text" style={{color: deco.mainColor}}>
                                                        {diffusionInfo.content_type}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-label" style={{color: deco.secondaryColor}}>
                                                    {translator("content_price")}
                                                </div>
                                                <div className="form-horizon-sub-item">
                                                    <div className="form-text" style={{color: deco.mainColor}}>
                                                        {diffusionInfo.content_price + " €"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-label" style={{color: deco.secondaryColor}}>
                                                    {translator("service_price")}
                                                </div>
                                                <div className="form-horizon-sub-item">
                                                    <div className="form-text" style={{color: deco.mainColor}}>
                                                        {diffusionInfo.service_price + " €"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-label" style={{color: deco.secondaryColor}}>
                                                    {translator("service_dates")}
                                                </div>
                                                <div className="form-horizon-sub-item">
                                                    <div className="form-text" style={{color: deco.mainColor}}>
                                                        {new Date(diffusionInfo.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng")) 
                                                        + " - " + 
                                                        new Date(diffusionInfo.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-info-panel">
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("content_id")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {diffusionInfo.content_unique_id}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("content_owner")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {diffusionInfo.content_owner_name}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("content_provider")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {diffusionInfo.content_provider_name}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("service_provider")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {diffusionInfo.service_provider_name}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("added_time")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {new Date(diffusionInfo.add_time).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("send_time_co_cp")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {new Date(diffusionInfo.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("valid_time_co_cp")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {new Date(diffusionInfo.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("send_time_cp_sp")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {new Date(diffusionInfo.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                    {translator("valid_time_cp_sp")}
                                                </div>
                                                <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                    {new Date(diffusionInfo.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            );
        }
    }
}

export default CheckDiffusionState;
