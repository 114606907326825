import React from "react";
import Lottie from "lottie-react";
import loader from "./icons/normal_loading.json";
import "./LoadingPage.css";

const LoadingPage = ({translator}) => {
    return (
        <div className="loading-page">
            <div className="loading-page-container">
                <Lottie animationData={loader} />
                <div className="loading-page-info-container">
                    <div className="loading-page-info-title">
                        {translator("loading")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;