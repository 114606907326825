import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import { showInfoPopUp, hideInfoPopUp } from '../components/PopUpManip';
import { getSpList, getAllUploadedDiffusion } from '../myContents/ContentManip';
import { getCpList } from '../offers/OfferManip';
import { getSuppliedContent } from '../blockchainManip/BCManip';
import { calculateContainerHeight, calculateTableWidth } from '../components/ContainerDimensionManip';
import BCLoadingPage from '../components/BCLoadingPage';
import NoContent from '../components/NoContent';
import Error3 from '../errorManip/Error3';
import { getContentCode, getCpCode } from '../blockchainManip/CpCode';
import { getCoByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const Revenu = ({translator, filters, coHash}) => {
    const [deco, setDeco] = useState(null);
    const [revenu, setRevenu] = useState(null);
    const [cps, setCps] = useState([]);
    const [sps, setSps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [tableSpWidth, setTableSpWidth] = useState("");
    const [tableContentPriceWidth, setTableContentPriceWidth] = useState("");
    const [tableCpRewardWidth, setTableCpRewardWidth] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableIncomeWidth, setTableIncomeWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [coNotExistError, setCoNotExistError] = useState(false);

    const getNameArray = (userArray, userType) => {
        var nameArray = [];
        for (let i = 0; i < userArray.length; i++) {
            const user = userArray[i];
            const name = userType==="cp" ? user.cp_name : user.sp_name;
            nameArray.push(name);
        }
        return nameArray;
    }

    const setCpFilter = (revenu) => {
        var selectedCp = sessionStorage.getItem("revenuCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            revenu = revenu.filter((r) => r.suppliedContentContentProvider===selectedCp);
        }
        return revenu;
    }

    const setSpFilter = (revenu) => {
        var selectedSp = sessionStorage.getItem("revenuSpFilter");
        if(selectedSp!==null && selectedSp!=="all"){
            revenu = revenu.filter((r) => r.suppliedContentServiceProvider===selectedSp);
        }
        return revenu;
    }

    const completeRevenuArray = (revenuArray) => {
        for (let i = 0; i < revenuArray.length; i++) {
            var revenu = revenuArray[i];
            if(revenu !== undefined){
                revenu = {
                    ...revenu,
                    info: [
                        {
                            label: "identifiant",
                            value: revenu.suppliedContentId
                        }
                    ]
                }
                revenuArray[i] = revenu;
            }
        }
        return revenuArray;
    }

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            const cpList = await getCpList().then(res => res.data);
            setCps(cpList);

            const spList = await getSpList().then(res => res.data);
            setSps(spList);

            const suppliedContents = await getAllUploadedDiffusion(coHash).then(res => res.data);
            let allSuppliedContents = [];

            for (let i = 0; i < suppliedContents.length; i++) {
                const content = suppliedContents[i];
                const cpCode = getCpCode(content.content_provider_name);
                const contentCode = getContentCode(content.content_unique_id, content.content_provider_name);
                let bcContent = await getSuppliedContent(contentCode, cpCode).then(res => res.data).catch((err) => {
                    setBcErrorOccured(true);
                    setBcErr({ name: err.name, msg: err.request.response });
                });
                bcContent = { ...bcContent, compareDate: content.valid_time_cp_sp };
                allSuppliedContents = [...allSuppliedContents, bcContent];
            }

            // just for preprod
            allSuppliedContents = completeRevenuArray(allSuppliedContents);
            allSuppliedContents = setCpFilter(allSuppliedContents);
            allSuppliedContents = setSpFilter(allSuppliedContents);

            allSuppliedContents.sort((contentA, contentB) => new Date(contentB.compareDate) - new Date(contentA.compareDate));

            setRevenu(allSuppliedContents);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name") === 0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp") === 0 ? "auto" : calculateTableWidth("table-cp") + "px";
            const tableSpWidthValue = calculateTableWidth("table-sp") === 0 ? "auto" : calculateTableWidth("table-sp") + "px";
            const tableContentPriceWidthValue = calculateTableWidth("table-content-price") === 0 ? "auto" : calculateTableWidth("table-content-price") + "px";
            const tableCpRewardWidthValue = calculateTableWidth("table-cp-reward") === 0 ? "auto" : calculateTableWidth("table-cp-reward") + "px";
            const tableViewerWidthValue = calculateTableWidth("table-viewer") === 0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableIncomeWidthValue = calculateTableWidth("table-income") === 0 ? "auto" : calculateTableWidth("table-income") + "px";

            if( contentHeightValue!== contentHeight || tableContentNameWidthValue !== tableContentNameWidth || tableCpWidthValue !== tableCpWidth || tableSpWidthValue !== tableSpWidth || tableContentPriceWidthValue !== tableContentPriceWidth || tableCpRewardWidthValue !== tableCpRewardWidth || tableViewerWidthValue !== tableViewerWidth || tableIncomeWidthValue !== tableIncomeWidth){
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCpWidth(tableCpWidthValue);
                setTableSpWidth(tableSpWidthValue);
                setTableContentPriceWidth(tableContentPriceWidthValue);
                setTableCpRewardWidth(tableCpRewardWidthValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableIncomeWidth(tableIncomeWidthValue);
            }
        };

        init();
    }, []);

    const countViewers = (viewersArray) => {
        viewersArray = viewersArray.filter((viewer) => viewer.viewerId!=="");
        var viewers = [...new Set(viewersArray.map(viewer => viewer.viewerId))];
        return viewers.length;
    }

    const countViews = (proofsArray) => {
        var validViews = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                validViews+=1;
            }
        }
        return validViews;
    }

    const calculateIncome = (price, contentType, proofsArray, cpReward) => {
        var views = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                views += 1;
            }
        }

        var n7Reward=0.23;
        switch (contentType) {
            case "Standard":
                n7Reward = 0.23;
                break;

            case "Premium":
                n7Reward = 0.5;
                break;
                
            case "Ultra Premium":
                n7Reward = 0.78;
                break;
        
            default:
                break;
        }

        const singlePrice = price - cpReward - n7Reward;
        const incomeValue = (views * singlePrice).toFixed(2);
        return Number(incomeValue).toFixed(2)  + " €";
    }

    if(revenu !== null){
        if(bcErrorOccured){
            return <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
        } else if(revenu.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cps={cps}
                                sps={sps} 
                            />
                            <div className="table-container" style={{maxHeight: contentHeight}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("content_name")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableCpWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("content_provider")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableSpWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("service_provider")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableContentPriceWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("content_price")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableCpRewardWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("cp_reward")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("viewer") + "/" + translator("views")}</p></th>
                                            <th style={{color: deco.secondaryColor, width: tableIncomeWidth}}><p style={{margin: 0, wordWrap: "normal"}}>{translator("income")}</p></th>
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {revenu.map((revenu, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-name">{revenu.suppliedContentName}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-cp">{revenu.suppliedContentContentProvider}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-sp">{revenu.suppliedContentServiceProvider}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-price">{revenu.suppliedContentPrice + "€"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-cp-reward">{revenu.suppliedContentCpReward + "€"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-viewer">{countViewers(revenu.viewerSubscription) + "/" + countViews(revenu.viewershipVerification)}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-income">{calculateIncome(revenu.suppliedContentPrice, revenu.suppliedContentType, revenu.viewershipVerification, revenu.suppliedContentCpReward)}</p></td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{visibility: "hidden"}}><InfoPopUp infos={revenu.info} /> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(revenu.length===0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cps={cps} 
                                sps={sps} 
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return(
            <BCLoadingPage translator={translator} />
        )
    }
}

export default Revenu;