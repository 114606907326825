import { useState } from 'react';

const useUser = () => {
    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        const user = JSON.parse(userString);
        return user;
    }
    const [user, setUser] = useState(getUser());
    const saveUser = user => {
        sessionStorage.setItem('user', JSON.stringify(user));
        setUser(user.user);
    };
    return {
        setUser: saveUser,
        user
    }
}

export default useUser;