const coUserDecoRoutes = [
    {
        id: 1,
        name: "LFP",
        logoPath: "lfp.jpeg",
        mainColor: "#70D460",
        secondaryColor: "#08142B",
        shadowColor: "0px 0px 6px rgba(112, 212, 96, 0.30)"
    },
    {
        id: 2,
        name: "La Liga",
        logoPath : "laliga.png",
        mainColor: "#FF4B44",
        secondaryColor: "#00001B",
        shadowColor: "0px 0px 6px #FF4B444D"
    },
    {
        id: 3,
        name: "Montreux Jazz Festival ",
        logoPath : "mjf.png",
        mainColor: "#000000",
        secondaryColor: "#FF9C01",
        shadowColor: "0px 0px 6px #0000004D"
    }
];
export default coUserDecoRoutes;
