import React, { useState, useEffect } from 'react';
import { Slider } from 'antd';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import { getCoOffer, getSuccessionNegotiationId, getSps, getSpDates, getCpSpValidity, acceptNegotiation, cancelOffer } from './OfferManip';
import { hoverCancelHandler, unhoverCancelHandler, hoverDeleteHandler, unhoverDeleteHandler } from '../components/ButtonManip';
import { calculateContainerHeight } from '../components/ContainerDimensionManip';
import Error1 from '../errorManip/Error1';
import { getCoByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const CheckOfferState = ({translator, filters, coHash}) => {
    const [deco, setDeco] = useState(null);
    const [coOrSpOffer, setCoOrSpOffer] = useState("");
    const [offer, setOffer] = useState(null);
    const [contentPriceList, setContentPriceList] = useState(null);
    const [sps, setSps] = useState([]);
    const [cpSpDates, setCpSpDates] = useState([]);
    const [cpSpValidity, setCpSpValidity] = useState("");
    const [contentHeight, setContentHeight] = useState("");
    const [coNotExistError, setCoNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const url = window.location.href;
            const urlArray = url.split("/");
            const negotiationId = urlArray[urlArray.length - 2];
            const coOrSpOffer = urlArray[urlArray.length - 3];

            setCoOrSpOffer(coOrSpOffer);

            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            let fetchedOffer = null;
            if (coOrSpOffer === "co") {
                fetchedOffer = await getCoOffer(negotiationId).then(res => res.data);
            }
            if (coOrSpOffer === "sp") {
                const successionNegotiation = await getSuccessionNegotiationId(negotiationId).then(res => res.data);
                
                if (successionNegotiation.length !== 0) {
                    const successionNegotiationId = successionNegotiation[0].succession_negotiation;
                    fetchedOffer = await getCoOffer(successionNegotiationId).then(res => res.data);

                    const fetchedSps = await getSps(successionNegotiationId).then(res => res.data.map(sp => sp.service_provider));
                    setSps(fetchedSps);

                    const fetchedCpSpDates = await getSpDates(successionNegotiationId).then(res => res.data[0]);
                    setCpSpDates(fetchedCpSpDates);

                    const fetchedCpSpValidity = await getCpSpValidity(successionNegotiationId).then(res => res.data[0].negotiation_valid);
                    setCpSpValidity(fetchedCpSpValidity);
                } else {
                    fetchedOffer = [];
                }
            }

            setOffer(fetchedOffer.length === 0 ? fetchedOffer : fetchedOffer[0]);
            setContentPriceList([{ type: "Standard", contentPrice: 1.5, n7Reward: 0.23 }, { type: "Premium", contentPrice: 2, n7Reward: 0.5 }, { type: "Ultra Premium", contentPrice: 3, n7Reward: 0.78 }]);

            const contentHeight = calculateContainerHeight();
            if (contentHeight !== contentHeight) {
                setContentHeight(contentHeight);
            }
        };

        init();
    }, []);

    const tagContainerStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "info-tag";

            case "accepted" : 
                return "valid-tag"

            case "sp_accepted":
                return "valid-tag"
        
            default:
                return "info-tag";
        }
    }

    const tagTextStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "info-tag-text"

            case "accepted" : 
                return "valid-tag-text"

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const cancelOfferHandler = (offerId) => {
        cancelOffer(offerId).then(
            () => {
                if(offer.negotiate_on!==null){
                    cancelOffer(offer.negotiate_on).then(() => {
                        alert(translator("offer_cancelled"));
                        window.location.assign("/" + coHash + "/offers")
                    })
                }
                alert(translator("offer_cancelled"));
                window.location.assign("/" + coHash + "/offers")
            }
        ).catch(
            () => {
                alert(translator("action_failed"))
            }
        )
    }

    const actionHandler = (valid, offerId) => {
        switch (valid) {
            case "":
                return (
                    <div className="form-button-panel">
                        <button className="n7-button cancel-button" onMouseEnter={e=>{hoverCancelHandler(e)}} onMouseLeave={e=>{unhoverCancelHandler(e)}} onClick={()=>cancelOfferHandler(offerId)}>{translator("cancel_offer")}</button>
                    </div> 
                )

            case "accepted" : 
                return (
                    <></>
                )
        
            default:
                return (
                    <></>
                );
        }
    }

    const getUniqueSp = (sps) => {
        for (let i = 0; i < sps.length; i++) {
            sps[i] = sps[i];
        }

        let uniqueSpArray = sps.filter(
            function(sp, i, self) {
                return i===self.indexOf(sp);
            }
        )
        return uniqueSpArray.join(", ");
    }

    const spHandler = () => {
        return (
            <>
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("service_provider")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {getUniqueSp(sps)}
                    </div>
                </div>
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("send_time_cp_sp")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(cpSpDates.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div>
                { cpSpValidity==="sp_accepted" ? 
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("valid_time_cp_sp")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(cpSpDates.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div> : <></>
                }
            </>
        )
    }

    if(offer !== null){
        if(offer.length===0 || coNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else {
            const sliderBorderColor = "0 0 0 2px " + deco.mainColor;
            const sliderShadowColor = "0 0 0 4px " + deco.mainColor;
            const priceInfoArray = contentPriceList.filter((array) => array.type===offer.content_type);
            const n7Reward = priceInfoArray[0].n7Reward;
            const contentPrice = priceInfoArray[0].contentPrice;
            const markMax = (contentPrice - n7Reward).toFixed(2);
            const cpReward = Number(offer.cp_reward)
    
            const sliderMark = {
                [cpReward]: {
                    style: {
                        color: deco.secondaryColor,
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500
                    }, 
                    label: cpReward + "€"
                }
            }

            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco}
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                tag={offer.negotiation_valid===null ? (coOrSpOffer==="co" ? translator("waiting_for_cp_reply") : translator("sp_negotiating")) : translator(offer.negotiation_valid)} 
                                tagContainerStyle={tagContainerStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}
                                tagTextStyle={tagTextStyleHandler(offer.negotiation_valid===null ?  "" : offer.negotiation_valid)}
                                returnPath={"/" + coHash + "/offers"}
                                contentName={offer.content_name}
                            />
                            <div className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_provider")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <button  
                                                    key={offer.content_provider} 
                                                    className="selected-tag selected-tag-text" 
                                                    style={{ border: "none", color: deco.mainColor, boxShadow: deco.shadowColor }}
                                                    disabled
                                                >
                                                    {offer.content_provider_name}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("cp_reward")}
                                            </div>
                                            <div style={{width: "100%", display: "inline-flex", flexDirection: "row", gap: "8px"}}>
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>0.01€</div>
                                                <Slider
                                                    style={{width:"100%", "--slider-border-color": sliderBorderColor, "--slider-shadow-color": sliderShadowColor, paddingTop: 0}}
                                                    defaultValue={offer.cp_reward}
                                                    handleStyle={{ top: "-20%" }}
                                                    disabled
                                                    marks={sliderMark}
                                                    min={0.01} max={markMax} step={0.01}
                                                />
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>{markMax + "€"}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_start_date")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {new Date(offer.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_end_date")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {new Date(offer.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {offer.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {offer.content_type}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("added_time")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(offer.add_time).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("send_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(offer.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        {offer.negotiation_valid==="accepted" ?
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(offer.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div> : <></>
                                        }
                                        {coOrSpOffer==="sp" ? spHandler() : ""}
                                    </div>
                                </div>
                                {/* {messageHandler(offer.negotiation_valid)} */}
                                {actionHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid, offer.negotiation_id)}    
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>             
                </div>
            )
        }
    }
}

export default CheckOfferState;


// const tagContainerStyleHandler = (valid) => {
//     switch (valid) {
//         case "":
//             return "info-tag";

//         case "rejected" : 
//             return "attention-tag"

//         case "negotiating" : 
//             return "attention-tag"

//         case "accepted" : 
//             return "valid-tag"

//         case "sp_negotiating":
//             return "info-tag"

//         case "sp_accepted":
//             return "valid-tag"
    
//         default:
//             return "info-tag";
//     }
// }

// const tagTextStyleHandler = (valid) => {
//     switch (valid) {
//         case "":
//             return "info-tag-text"
    
//         case "rejected" : 
//             return "attention-tag-text"

//         case "negotiating" : 
//             return "attention-tag-text"

//         case "accepted" : 
//             return "valid-tag-text"

//         case "sp_negotiating":
//             return "info-tag-text"

//         case "sp_accepted":
//             return "valid-tag-text"
    
//         default:
//             return "info-tag-text";
//     }
// }

// const messageHandler = (valid) => {
//     switch (valid) {
//         case "rejected":
//             return (
//                 <div className="form-comment-panel">
//                     <div className="form-comment-label" style={{color: deco.secondaryColor}}>{translator("rejection_msg")}</div>
//                     <div className="form-comment-text" style={{color: deco.secondaryColor}}>{offer.cp_message}</div>
//                 </div>
//             )

//         case "negotiating":
//             return (
//                 <div className="form-comment-panel">
//                     <div className="form-comment-label" style={{color: deco.secondaryColor}}>{translator("negotiation_msg")}</div>
//                     <div className="form-comment-text" style={{color: deco.secondaryColor}}>{offer.cp_message}</div>
//                 </div>
//             )
    
//         default:
//             break;
//     }
// }

// const setUTCDate = (date) => {
//     const dateFormatDate = new Date(date);
//     return Date.UTC(dateFormatDate.getUTCFullYear(), 
//                     dateFormatDate.getUTCMonth(), 
//                     dateFormatDate.getUTCDate(), 
//                     dateFormatDate.getUTCHours(),
//                     dateFormatDate.getUTCMinutes(),
//                     dateFormatDate.getUTCSeconds());
// }

// const acceptNegotiationHandler = (negotiationId) => {
//     const validTime = new Date();
//     const validUTCTime = new Date(setUTCDate(validTime)).toISOString();
//     const negotiation = {
//         negotiationId: negotiationId,
//         validTime: validUTCTime
//     }
//     acceptNegotiation(negotiation).then(
//         () => {
//             alert(translator("negotiation_accepted"));
//             window.location.assign("/" + coHash + "/offers")
//         }
//     ).catch(
//         () => {
//             alert(translator("action_failed"))
//         }
//     )
// }

// const cancelOfferHandler = (offerId) => {
//     cancelOffer(offerId).then(
//         () => {
//             if(offer.negotiate_on!==null){
//                 cancelOffer(offer.negotiate_on).then(() => {
//                     alert(translator("offer_cancelled"));
//                     window.location.assign("/" + coHash + "/offers")
//                 })
//             }
//             alert(translator("offer_cancelled"));
//             window.location.assign("/" + coHash + "/offers")
//         }
//     ).catch(
//         () => {
//             alert(translator("action_failed"))
//         }
//     )
// }

// const actionHandler = (valid, offerId) => {
//     switch (valid) {
//         case "":
//             return (
//                 <div className="form-button-panel">
//                     <button className="n7-button cancel-button" onMouseEnter={e=>{hoverCancelHandler(e)}} onMouseLeave={e=>{unhoverCancelHandler(e)}} onClick={()=>cancelOfferHandler(offerId)}>{translator("cancel_offer")}</button>
//                 </div> 
//             )

//         case "rejected" : 
//             return (
//                 <div className="form-button-panel">
//                     <button className="n7-button delete-button" onMouseEnter={e=>{hoverDeleteHandler(e)}} onMouseLeave={e=>{unhoverDeleteHandler(e)}} onClick={()=>cancelOfferHandler(offerId)}>{translator("delete_offer")}</button>
//                 </div> 
//             )

//         case "negotiating" : 
//             return (
//                 <div className="form-button-panel">
//                     <button className="n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} style={{ color: deco.secondaryColor }} onClick={()=>{acceptNegotiationHandler(offerId)}}>{translator("validate_proposal")}</button> 
//                     <button className="n7-button cancel-button" onMouseEnter={e=>{hoverCancelHandler(e)}} onMouseLeave={e=>{unhoverCancelHandler(e)}} onClick={()=>{cancelOffer(offerId)}}>{translator("cancel_offer")}</button>
//                 </div> 
//             ) 

//         case "accepted" : 
//             return (
//                 <></>
//             )
    
//         default:
//             return (
//                 <></>
//             );
//     }
// }

// const getUniqueSp = (sps) => {
//     for (let i = 0; i < sps.length; i++) {
//         sps[i] = sps[i];
//     }

//     let uniqueSpArray = sps.filter(
//         function(sp, i, self) {
//             return i===self.indexOf(sp);
//         }
//     )
//     return uniqueSpArray.join(", ");
// }

// const spHandler = () => {
//     return (
//         <>
//             <div className="form-info-item">
//                 <div className="form-info-label" style={{color: deco.secondaryColor}}>
//                     {translator("service_provider")}
//                 </div>
//                 <div className="form-info-text" style={{color: deco.secondaryColor}}>
//                     {getUniqueSp(sps)}
//                 </div>
//             </div>
//             <div className="form-info-item">
//                 <div className="form-info-label" style={{color: deco.secondaryColor}}>
//                     {translator("send_time_cp_sp")}
//                 </div>
//                 <div className="form-info-text" style={{color: deco.secondaryColor}}>
//                     {new Date(cpSpDates.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
//                 </div>
//             </div>
//             { cpSpValidity==="sp_accepted" ? 
//             <div className="form-info-item">
//                 <div className="form-info-label" style={{color: deco.secondaryColor}}>
//                     {translator("valid_time_cp_sp")}
//                 </div>
//                 <div className="form-info-text" style={{color: deco.secondaryColor}}>
//                     {new Date(cpSpDates.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
//                 </div>
//             </div> : <></>
//             }
//         </>
//     )
// }