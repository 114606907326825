import React, { useState, useEffect } from "react";
import CryptoJs from "crypto-js";
import { Link } from "react-router-dom"; 
import { Form, Input } from "antd";
import "../components/UserLogin.css";
import {getUserInfo} from "./UserManip";
import LoginNavbar from "../components/LoginNavbar";
import LoadingButton from '../components/LoadingButton';
import {hoverSubmitHandler, unhoverSubmitHandler} from "../components/ButtonManip";
import PropTypes from "prop-types";

const SignIn = ({translator, setUser}) => {

    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [coUserName, setCoUserName] = useState("");
    const [coUserPwd, setCoUserPwd] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const getId = async () => {
        return getUserInfo(coUserName).then(res => {
            if(res.data.length===0){
                throw new Error(translator("wrong_user_name"));
            } else {
                return res.data[0];
            }
        });
    }

    const getClientHashId = (coId) => {
        var hash = CryptoJs.SHA256(coId.toString());
        var hashHex = hash.toString(CryptoJs.enc.Hex);
        var hashId = hashHex.substring(0, 8);
        return hashId;
    }

    const onFinish = async () => {
        setShowLoader(true);
        try {
            const userInfo = await getId();
            cognitoAuthSDK.login(coUserName, coUserPwd, (error, token) => {
                if(error) {
                    alert(translator("wrong_user_name_or_wrong_pwd"));
                    setShowLoader(false);
                } else {
                    const coId = userInfo.co_id;
                    const hashCoId = getClientHashId(coId);
                    let user = {
                        id: userInfo.co_user_id,
                        coUserName: userInfo.co_user_name,
                        token: token
                    }
                    setUser(user);
                    const uri = "/" + hashCoId + "/myContents";
                    window.location.assign(uri);
                }
            })
        } catch(error){
            alert(error);
            window.location.reload();
        }
    }

    const coUserNameHandler = (event) => {
        setCoUserName(event.target.value);
    }
    
    const coUserPwdHandler = (event) => {
        setCoUserPwd(event.target.value);
    }

    const formVerification = () => {
        const userNameNotNull = coUserName!=="";
        const userPwdNotNull = coUserPwd!=="";
        if(userNameNotNull&&userPwdNotNull){
            return (
                <div className="login-submit-btn">
                    <LoadingButton 
                        text={translator('sign_in')} 
                        className="n7-button normal-button" 
                        onSubmit={onFinish} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    />
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
                
            )
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("sign_in")}</button>
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
            )
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.CoUserAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="wrapper">
            <div className="navbar">
                <LoginNavbar />
            </div>
            <div className="login-container">
                <div className="login-form-container">
                    <div className="login-input-container">
                        <Form 
                            name="normal_login"
                            layout="vertical"
                            className="login-inputs"
                        >
                            <Form.Item
                                name="content_owner_user_name"
                                label={translator("user_name")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_name_msg')
                                }]}>
                                <Input  
                                    placeholder={ translator('your_user_name') } 
                                    autoComplete="off"
                                    value={coUserName}
                                    onChange={coUserNameHandler}/>
                            </Form.Item>
                            <Form.Item
                                name="content_owner_user_pwd"
                                label={translator("pwd")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_pwd_msg')
                                }]}>
                                <Input.Password
                                    type="password"
                                    placeholder={translator('your_pwd')}
                                    autoComplete="off"
                                    value={coUserPwd}
                                    onChange={coUserPwdHandler}/>
                            </Form.Item> 
                        </Form>
                    </div>
                    {formVerification()}
                </div>
            </div>
            <div className="footer">
                <div className="footer-left-panel">
                    <div className="footer-left-panel-about-n7">
                        <a id="about-n7-link" href="#">
                            {translator('about_n7_project')}
                        </a>
                    </div>
                    <div className="footer-left-panel-contact">
                        <a id="contact-link" href="#">
                            {translator('contact_n7')}
                        </a>
                    </div>
                </div>
                <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
            </div>
        </div>
    )
}

SignIn.propTypes = {
    setUser: PropTypes.func.isRequired,
};

export default SignIn;
