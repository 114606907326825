import React, { useState, useEffect } from "react";

const CpFilter = ({translator, deco, activeFilterGeneral, activeFilterHandler, activeFilter, deactiveFilter, hoverHandler, unhoverHandler, cps, selectCp, getCpFilterPage}) => {

    const [cpList, setCpList] = useState([]);

    useEffect(() => {
        const cpNames = cps.map(cp => cp.cp_name);
        setCpList(["all", ...cpNames]);
    }, [cps])

    return (
        <div className="sidebar-filter" id="cp-filter">
            <div className="sidebar-filter-title" style={{ color: deco.secondaryColor }}>
                {translator("content_provider_full")}
            </div>
            <div className="sidebar-filter-container">
                {
                    cpList.map(
                        cp => (
                            activeFilterHandler("cp", cp) ? activeFilter("cp", cp) : deactiveFilter("cp", cp)
                        )
                    )
                }
            </div>
            
        </div>
    )
}

export default CpFilter;