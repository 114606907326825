import axios from "axios";

const BASIC_URL="https://n7-demo-content-owner.nseven.xyz/api/v1/co";

const getAllMyContents = (coHashId) => {
    const url=BASIC_URL + "/contents/" + coHashId + "/all";
    return axios.get(url);
}

const getContentByName = (coHashId, contentName) => {
    let content = {
        coHashId: coHashId,
        contentName: contentName
    }
    const url=BASIC_URL + "/contents/" + coHashId + "/name";
    return axios.post(url, content);
}

const getContentById = (contentId) => {
    const url=BASIC_URL + "/contents/id/" + contentId; 
    return axios.get(url);
}

const uploadContentInfo = (contentInfo) => {
    const url=BASIC_URL + "/content/new";
    return axios.post(url, contentInfo);
}

const updateUploadState = (content) => {
    const url=BASIC_URL + "/contents/uploadState";
    return axios.put(url, content);
}

const deleteContentInfo = (contentUniqueId) => {
    const url=BASIC_URL + "/content/" + contentUniqueId;
    return axios.delete(url);
}

const getAllDiffusion = (coHash) => {
    const url=BASIC_URL + "/diffusions/" + coHash + "/all";
    return axios.get(url);
}

const getAllUploadedDiffusion = (coHash) => {
    const url=BASIC_URL + "/diffusions/" + coHash + "/uploaded";
    return axios.get(url);
}

const getDiffusion = (coHash, contentStateSpId) => {
    const url = BASIC_URL + "/diffusions/" + coHash + "/contentState/" + contentStateSpId;
    return axios.get(url);
}

const getSpList = () => {
    const url=BASIC_URL + "/diffusions/spList";
    return axios.get(url);
}

export {getAllMyContents, getContentByName, getContentById, uploadContentInfo, deleteContentInfo, updateUploadState, getAllDiffusion, getAllUploadedDiffusion, getDiffusion, getSpList};
