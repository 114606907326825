import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import LoadingButton from '../components/LoadingButton';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import "./MyContents.css";
import {getAllAssets, uploadContent, deleteContent} from './AssetManip';
import {getContentByName, uploadContentInfo, deleteContentInfo} from './ContentManip';
import {getCoByHash} from "../userLogin/UserManip";
import {hoverSubmitHandler, unhoverSubmitHandler, hoverDeleteHandler, unhoverDeleteHandler, hoverCancelHandler, unhoverCancelHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import {getCpList} from '../offers/OfferManip';
import LoadingPage from "../components/LoadingPage";
import NoContent from '../components/NoContent';
import Error1 from '../errorManip/Error1';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const MyContents = ({translator, coHash}) => {
    const [coId, setCoId] = useState(null);
    const [deco, setDeco] = useState(null);
    const [myAssets, setMyAssets] = useState(null);
    const [myContents, setMyContents] = useState(null);
    const [allCps, setAllCps] = useState([]);
    const [uploadContentBoxShown, setUploadContentBoxShown] = useState("");
    const [selectedContent, setSelectedContent] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableAddDateWidth, setTableAddDateWidth] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [coNotExistError, setCoNotExistError] = useState(false);
    
    const completeMyContentArray = (myContentArray) => {
        return myContentArray.map(myContent => {
            return {
                ...myContent,
                info: [
                    {
                        label: "identifiant",
                        value: myContent.content_unique_id
                    }
                ]
            }
        })
    }

    const uploadedArray = (content) => {
        const uploadChannelString = content.uploaded_to_bc;

        const uploadChannelArray = uploadChannelString.split(",");
        return uploadChannelArray;
    }

    const sendOfferHandler = (content) => {
        const allCpsName = allCps.map(cp => cp.cp_name);
        const cpInNego = uploadedArray(content);
        const equality = allCpsName.every(cp => cpInNego.indexOf(cp) > -1);
        if(equality){ 
            return (
                <button className="table-action-buttons n7-button disabled-button" disabled style={{color: deco.secondaryColor}}>{translator("send_proposal")}</button>
            )
        } else {
            return(
                <Link to={"/" + coHash +"/myContents/" + content.content_unique_id + "/sendOffer"}>
                    <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("send_proposal")} style={{color: deco.secondaryColor}}/>
                </Link>
            )
        }
        
    }

    const showUploadContentBox = () => {
        setUploadContentBoxShown(true);
    }

    const hideUploadContentBox = () => {
        setUploadContentBoxShown(false);
        setSelectedContent(null);
    }

    const browseContentClickHandler = () => {
        document.getElementById('selected-content-input').click();
    };

    const handleUploadContentChange = (e) => {
        const selectedContent = e.target.files[0];
        setSelectedContent(selectedContent);
    };

    const handleUploadContentDragOver = (e) => {
        e.preventDefault();
    };

    const handleUploadContentDrop = (e) => {
        e.preventDefault();
        const selectedContent = e.dataTransfer.files[0];
        setSelectedContent(selectedContent);
    };

    const contentSizeFormatHandler = (bytes) => {
        if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const unit = ['Bytes', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
        
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + unit[i];
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const uploadHandler = () => {
        setShowLoader(true);
        const formData = new FormData();
        formData.append('file', selectedContent);

        uploadContent(formData).then(response => {
            if (response.status === 202) {
                const contentName = selectedContent.name;
                const contentNameWithoutExtension = contentName.substr(0, contentName.lastIndexOf('.'));
                const contentOwner = coId;
                const addTime = new Date(setUTCDate(new Date())).toISOString();
                let contentInfo = {
                    contentName: contentNameWithoutExtension,
                    contentOwner: contentOwner,
                    addTime: addTime
                }
                uploadContentInfo(contentInfo).then(() => {
                    setShowLoader(false);
                    alert(translator("content_uploaded"));
                    window.location.reload();
                })
            } else {
                setShowLoader(false);
                throw new Error(translator("content_uploaded_failed"));
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert(translator("content_uploaded_failed"));
            window.location.reload();
        });
    };

    const deleteUploadedContentHandler = (contentName, contentUniqueId) => {
        const foundContent = myAssets.find(asset => asset.filename.substr(0, asset.filename.lastIndexOf('.'))===contentName);
        
        if(foundContent!==undefined){
            const contentNameWithExtension = foundContent.filename;
            let selectedContent = {
                filename: contentNameWithExtension
            }
            deleteContent(selectedContent).then(res => {
                if(res.status === 202){
                    deleteContentInfo(contentUniqueId).then(() => {
                        alert(translator("content_deleted"));
                        window.location.reload();
                    })
                } else {
                    throw new Error(translator("content_deleted_failed"))
                }
            }).catch(error => {
                alert(translator("content_deleted_failed"));
                window.location.reload();
            })
        }
    }

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setCoId(coData[0].co_id);
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }
            
            var allAssets = await getAllAssets().then(res => res.data).catch(err => console.error(err));
            allAssets = allAssets.filter(asset => asset.filename!==".gitignore");
            setMyAssets(allAssets);
            var allContents = [];
            for (let i = 0; i < allAssets.length; i++) {
                const assetName = allAssets[i].filename
                const assetNameWithoutExtension = assetName.substr(0, assetName.lastIndexOf('.'));
                const content = await getContentByName(coHash, assetNameWithoutExtension).then(res => res.data).catch(res => res.data);
                if(content!==undefined && content.length!==0){
                    allContents.push(content[0]);
                }
            }
            const allCpsData = await getCpList().then(res => res.data);
            const completedContents = completeMyContentArray(allContents);
            setMyContents(completedContents);
            setAllCps(allCpsData);

            const heightValue = calculateContainerHeight();
            const contentNameWidthValue = calculateTableWidth("table-content-name") === 0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const addDateWidthValue = calculateTableWidth("table-add-date") === 0 ? "auto" : calculateTableWidth("table-add-date") + "px";
            if(heightValue !== contentHeight || contentNameWidthValue !== tableContentNameWidth || addDateWidthValue !== tableAddDateWidth){
                setContentHeight(heightValue);
                setTableContentNameWidth(contentNameWidthValue);
                setTableAddDateWidth(addDateWidthValue);
            }
        };

        init();
    }, []);

    if(myAssets!==null && myContents!==null){
        if(coNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(myContents.length !== 0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="upload-content-btn">
                                <button className="n7-button normal-button" onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler} onClick={showUploadContentBox} style={{whiteSpace: "nowrap", color: deco.secondaryColor}}>
                                    {translator("upload_local_content")}
                                </button>
                                <Modal show={uploadContentBoxShown} onHide={hideUploadContentBox} style={{ position: "fixed", zIndex: "9999" }}>
                                    <Modal.Header>
                                        <Modal.Title style={{ color: deco.secondaryColor }}>
                                            {translator('upload_local_content')}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="upload-content-modal-body">
                                        {
                                            selectedContent ? 
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{color: deco.secondaryColor}}>{translator("content_name")}</th>
                                                        <th style={{color: deco.secondaryColor, paddingLeft: "10px"}}>{translator("content_size")}</th>                 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{color: deco.secondaryColor}}>{selectedContent.name}</td>
                                                        <td style={{color: deco.secondaryColor, paddingLeft: "10px"}}>{contentSizeFormatHandler(selectedContent.size)}</td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <div className="drag-area" onDragOver={handleUploadContentDragOver} onDrop={handleUploadContentDrop}>
                                                <div className="icon" style={{ marginRight: "10px" }}><i className="fas fa-cloud-upload-alt"></i></div>
                                                <header style={{ color: deco.secondaryColor }}>{translator("drag_and_drop_or")}</header>
                                                <button className="n7-button normal-button" style={{ marginLeft: "10px" }} onClick={browseContentClickHandler}>{translator("browse_file")}</button>
                                                <input id="selected-content-input" type="file" onChange={handleUploadContentChange} hidden />
                                            </div>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <LoadingButton 
                                            text={translator("upload")} 
                                            color={deco.secondaryColor}
                                            onSubmit={()=>uploadHandler()} 
                                            loading={showLoader} 
                                            disabled={showLoader} 
                                        />
                                        <button style={{marginLeft: "10px"}} onMouseEnter={hoverCancelHandler} onMouseLeave={unhoverCancelHandler} className="n7-button cancel-button" onClick={hideUploadContentBox}>{translator("close")}</button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="main-panel-container">
                                <div className="table-container" style={{ maxHeight: contentHeight }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{color: deco.secondaryColor, width: tableContentNameWidth}}><p style={{margin:0, width: "max-content"}}>{translator("content_name")}</p></th>
                                                <th scope="col" style={{color: deco.secondaryColor, width: tableAddDateWidth}}><p style={{margin: 0, width: "max-content"}}>{translator("upload_date")}</p></th>
                                                <th scope="col" style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                                <th scope="col" style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {myContents.map((content, id) => 
                                                <tr key = {content.content_unique_id} >
                                                    <td style={{ color: deco.secondaryColor}}><p className="table-content-name" style={{margin: 0, width: "max-content"}}>{content.content_name}</p></td>
                                                    <td style={{ color: deco.secondaryColor}}><p className="table-add-date" style={{margin:0, width: "max-content"}}>{new Date(content.add_time).toLocaleDateString(localStorage.getItem("i18nextLng"))}</p></td>
                                                    <td style={{ color: deco.secondaryColor, whiteSpace: "nowrap" }}>
                                                        {sendOfferHandler(content)}
                                                        <button className="n7-button delete-button" onMouseEnter={e=>{hoverDeleteHandler(e)}} onMouseLeave={e=>{unhoverDeleteHandler(e)}} onClick={()=>{deleteUploadedContentHandler(content.content_name, content.content_unique_id)}}>{translator("delete")}</button>
                                                    </td>
                                                    <td className="table-infomation-panel" style={{ color: deco.secondaryColor, paddingRight: "15px", textAlign: "right"}}>
                                                        <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={content.info} /> </span>
                                                        <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(myContents.length===0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="upload-content-btn">
                                <button className="n7-button normal-button" onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler} onClick={showUploadContentBox} style={{whiteSpace: "nowrap", color: deco.secondaryColor}}>
                                    {translator("upload_local_content")}
                                </button>
                                <Modal show={uploadContentBoxShown} onHide={hideUploadContentBox} style={{ position: "fixed", zIndex: "9999" }}>
                                    <Modal.Header>
                                        <Modal.Title style={{ color: deco.secondaryColor }}>
                                            {translator('upload_local_content')}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="upload-content-modal-body">
                                        {
                                            selectedContent ? 
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{color: deco.secondaryColor}}>{translator("content_name")}</th>
                                                        <th style={{color: deco.secondaryColor, paddingLeft: "10px"}}>{translator("content_size")}</th>                 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{color: deco.secondaryColor}}>{selectedContent.name}</td>
                                                        <td style={{color: deco.secondaryColor, paddingLeft: "10px"}}>{contentSizeFormatHandler(selectedContent.size)}</td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <div className="drag-area" onDragOver={handleUploadContentDragOver} onDrop={handleUploadContentDrop}>
                                                <div className="icon" style={{ marginRight: "10px" }}><i className="fas fa-cloud-upload-alt"></i></div>
                                                <header style={{ color: deco.secondaryColor }}>{translator("drag_and_drop_or")}</header>
                                                <button className="n7-button normal-button" style={{ marginLeft: "10px" }} onClick={browseContentClickHandler}>{translator("browse_file")}</button>
                                                <input id="selected-content-input" type="file" onChange={handleUploadContentChange} hidden />
                                            </div>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <LoadingButton 
                                            text={translator("upload")} 
                                            color={deco.secondaryColor}
                                            onSubmit={()=>uploadHandler()} 
                                            loading={showLoader} 
                                            disabled={showLoader} 
                                        />
                                        <button className="n7-button cancel-button" style={{marginLeft: "10px"}} onMouseEnter={hoverCancelHandler} onMouseLeave={unhoverCancelHandler} onClick={hideUploadContentBox}>{translator("close")}</button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="main-panel-container">
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } 
        }
    } else {  
        return (
            <LoadingPage translator={translator} />
        )
    }
}


export default MyContents;