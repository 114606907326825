const getDeco = (coUserDecoRoutes, coName) => {
    const foundCo = coUserDecoRoutes.find(co => co.name === coName);

    return foundCo ? {
        logoPath : foundCo.logoPath,
        mainColor : foundCo.mainColor,
        secondaryColor : foundCo.secondaryColor,
        shadowColor : foundCo.shadowColor
    } : null;
}

export default getDeco;