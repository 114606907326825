const showInfoPopUp = (popUpId) => {
    var containerId = "info-pop-up-container-" + popUpId;
    var popUpContainer = document.getElementById(containerId);
    popUpContainer.style.visibility = "visible";
}

const hideInfoPopUp = (popUpId) => {
    var containerId = "info-pop-up-container-" + popUpId;
    var popUpContainer = document.getElementById(containerId);
    popUpContainer.style.visibility = "hidden";
}

export {showInfoPopUp, hideInfoPopUp};