import React, { useState, useEffect } from 'react';
import { Form, Slider, DatePicker } from 'antd';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getAllAssets, createNewStream} from '../myContents/AssetManip';
import {getCpList, getCoOffersByContent, sendOffer} from './OfferManip';
import {hoverHandler, unhoverHandler, hoverCancelHandler, unhoverCancelHandler} from '../components/ButtonManip';
import { getCoByHash } from '../userLogin/UserManip';
import {getContentById, updateUploadState} from '../myContents/ContentManip';
import {addContent} from '../blockchainManip/BCManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import LoadingButton from '../components/LoadingButton';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import LoadingPage from '../components/LoadingPage';
import orgNameNoStyleHandler from '../components/OrgNameNoStyleManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const SendOffer = ({translator, filters, coHash}) => {
    const [co, setCo] = useState(null);
    const [deco, setDeco] = useState(null);
    const [offers, setOffers] = useState([]);
    const [cpList, setCpList] = useState(null);
    const [form] = Form.useForm();
    const typeList = [
        { value: "Standard", label: "Standard" },
        { value: "Premium", label: "Premium" },
        { value: "Ultra Premium", label: "Ultra Premium" }
    ]
    const contentPriceList = [
        { type: "Standard", contentPrice: 1.5, n7Reward: 0.23 },
        { type: "Premium", contentPrice: 2, n7Reward: 0.5 },
        { type: "Ultra Premium", contentPrice: 3, n7Reward: 0.78 }
    ];
    const [contentInfo, setContentInfo] = useState(null);
    const [assets, setAssets] = useState(null);
    const [cps, setCps] = useState([]);
    const [contentType, setContentType] = useState("Standard");
    const [cpReward, setCpReward] = useState(0);
    const [availabilityStartDate, setAvailabilityStartDate] = useState(new Date());
    const [availabilityEndDate, setAvailabilityEndDate] = useState(null);
    const dateFormat = {fr: "DD/MM/YYYY", en: "MM/DD/YYYY"};
    const [showLoader, setShowLoader] = useState(false);
    const [contentHeight, setContentHeight] = useState("");
    const [coNotExistError, setCoNotExistError] = useState(false);
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});

    useEffect(() => {
        const init = async () => {
            const url = window.location.pathname;
            const urlArray = url.split("/");
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setCo(coData[0]);
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }
            
            var allAssets = await getAllAssets().then(res => res.data).catch(err => console.error(err));
            allAssets = allAssets.filter(asset => asset.filename!==".gitignore");
            setAssets(allAssets);
            
            const cpListData = await getCpList().then(res => res.data);
            const modifiedCpList = cpListData.map(cp => ({
                value: cp.cp_id,
                label: cp.cp_name
            }));
            setCpList(modifiedCpList);

            const contentId = urlArray[urlArray.length - 2];
            const contentInfoData = await getContentById(contentId).then(res => res.data);
            setContentInfo(contentInfoData.length === 0 ? contentInfoData : contentInfoData[0]);

            var offersData = [];
            if (contentInfoData.length !== 0) {
                offersData = await getCoOffersByContent(contentInfoData[0].content_unique_id).then(res => res.data);
            }
            setOffers(offersData);

            const contentHeightValue = calculateContainerHeight();
            if (contentHeightValue !== contentHeight) {
                setContentHeight(contentHeightValue);
            }
        };

        init();
    }, []);

    useEffect(() => {
        const initialValue = form.getFieldValue("cpReward");
        if(cpReward!==0 && initialValue!==undefined){
            if(cpReward!==initialValue){
                form.setFieldsValue({ cpReward: cpReward });
            }
        }
    }, [form, cpReward]);

    const selectCp = (cpSelected) => {
        let modifiedCp = [...cps, cpSelected];
        setCps(modifiedCp);
    }

    const unselectCp = (cpSelected) => {
        let modifiedCp = cps;
        modifiedCp = modifiedCp.filter((cp) => cp!==cpSelected);
        setCps(modifiedCp);
    }

    const selectedCp = (cp) => {
        let contentProvider = {
            cpId: cp.value,
            cpName: cp.label
        }
        return ( 
            <button  
                key={cp.value} 
                className="selected-tag selected-tag-text" 
                style={{ border: "none", color: deco.mainColor, boxShadow: deco.shadowColor }}
                onClick={() => {unselectCp(contentProvider)}}
            >
                {cp.label}
            </button>
        )
    }

    const unselectedCp = (cp) => {
        const uploadedArray = uploadedArrayHandler();
        // content can be uploaded but the offer has been cancelled
        // check if the offer has been cancelled
        const cpOffers = offers.filter(offer => offer.content_provider===cp.value);
        
        // if content is uploaded and the there's an offer
        if(uploadedArray.includes(cp.label) && cpOffers.length!==0){
            return(
                <button  
                    key={cp.value} 
                    className="info-tag info-tag-text" 
                    style={{ border: "none", color: deco.secondaryColor }}
                    disabled
                >
                    {cp.label}
                </button>
            )
        } else { 
            let contentProvider = {
                cpId: cp.value,
                cpName: cp.label
            }
            return(
                <button  
                    key={cp.value} 
                    className="normal-tag normal-tag-text" 
                    style={{border: "none", color: deco.secondaryColor}}
                    onMouseEnter={e=>{hoverHandler(e, deco.mainColor, deco.shadowColor)}}
                    onMouseLeave={e=>{unhoverHandler(e, deco.secondaryColor)}}
                    onClick={()=>{selectCp(contentProvider)}}
                >
                    {cp.label}
                </button>
            )
        }
    }

    const selectType = (typeSelected) => {
        const priceInfoArray = contentPriceList.filter((array) => array.type===typeSelected);
        const n7Reward = priceInfoArray[0].n7Reward;
        const contentPrice = priceInfoArray[0].contentPrice;
        const markMax = Number((contentPrice - n7Reward).toFixed(2));
        const cpRewardValue = Number(markMax/2).toFixed(2);
        setCpReward(cpRewardValue);
        setContentType(typeSelected);
    }

    const unselectType = (typeSelected) => {
        setContentType(typeSelected);
    }

    const selectedType = (type) => {
        return ( 
            <button  
                key={type.value} 
                className="selected-tag selected-tag-text" 
                style={{border: "none", color: deco.mainColor, boxShadow: deco.shadowColor}}
                onClick={()=>{unselectType(type.value)}}
            >
                {type.label}
            </button>
        )
    }

    const unselectedType = (type) => {
        return(
            <button  
                key={type.value} 
                className="normal-tag normal-tag-text" 
                style={{border: "none", color: deco.secondaryColor}}
                onMouseEnter={e=>{hoverHandler(e, deco.mainColor, deco.shadowColor)}}
                onMouseLeave={e=>{unhoverHandler(e, deco.secondaryColor)}}
                onClick={()=>{selectType(type.value)}}
            >
                {type.label}
            </button>
        )
    }

    const cpRewardHandler = (cpReward) => {
        setCpReward(cpReward);
    }


    const availabilityStartDateHandler = (startDate) => {
        setAvailabilityStartDate(startDate);
    }

    const validStartTime = () => {
        const startDate = new Date(availabilityStartDate);
        const today = new Date();
        today.setHours(0,0,0,0);
        const stateStartDate = new Date(availabilityStartDate);
        stateStartDate.setHours(0,0,0,0);
        if(startDate.getTime() < today.getTime() && availabilityStartDate!==null) {
            return {
                validateStatus: "error",
                msg: translator("enter_correct_availability_start_date_msg")
            } 
        } else {
            return {
                validateStatus: "success",
                msg: ""
            }
        }
    }

    const defaultAvailabilityEndDate = () => {
        const startDate = availabilityStartDate===null ? new Date() : new Date(availabilityStartDate);
        const defaultEndDate = new Date(startDate.setUTCDate(startDate.getUTCDate() + 2));
        return defaultEndDate;
    }

    const availabilityEndDateHandler = (endDate) => {
        setAvailabilityEndDate(endDate);
    }

    const validEndTime = () => {
        const startDate = new Date(availabilityStartDate);
        startDate.setHours(0,0,0,0);
        const endDate = new Date(availabilityEndDate);
        endDate.setHours(0,0,0,0);
        if(endDate.getTime() > startDate.getTime() || availabilityEndDate === null){
            return {
                validateStatus: "success",
                msg: ""
            }
        } else {
            return {
                validateStatus: "error",
                msg: translator("enter_correct_availability_end_date_msg")
            }
        }
    }

    const accessDurationHandler = () => {
        if(availabilityStartDate!==null){
            const end = availabilityEndDate===null ? defaultAvailabilityEndDate() : new Date(availabilityEndDate);
            end.setHours(0,0,0,0);
            const start = new Date(availabilityStartDate);
            start.setHours(0,0,0,0);
            const diffDate = new Date(end-start);
            diffDate.setHours(0,0,0,0);
            const diffDateDay = diffDate.getDate();
            const diffDateMonth = diffDate.getMonth() + 1;
            const diffDateYear = diffDate.getFullYear();
            const diffArray = [diffDateDay, diffDateMonth, diffDateYear];
            const diffDay = Number(Math.abs(diffArray[0]) - 1);
            const diffMonth = Number(Math.abs(diffArray[1]) -1);
            const diffYear = Number(Math.abs(diffArray[2]) - 1970);
            const duration = diffYear + " " + translator("year_s") + " " + diffMonth + " " + translator("month_s") + " " + diffDay + " " + translator("day_s");
            return duration;
        }
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const validity = () => {
        const dateValidity = validStartTime().validateStatus==="success" && validEndTime().validateStatus==="success";
        const cpSelected = cps.length!==0;
        if(dateValidity && cpSelected){
            return <LoadingButton 
                        text={translator("validate_proposal")} 
                        className="n7-button normal-button" 
                        color={deco.secondaryColor} 
                        onSubmit={()=>sendNegotiation()} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    />
        } else {
            return <button className="n7-button disabled-button" disabled style={{color: deco.secondaryColor}}>{translator("validate_proposal")}</button> 
        }
    }
    
    const sendOfferValid = async (cpCoNegotiation) => {
        return sendOffer(cpCoNegotiation).then(() => {
            return "offer_sent"
        })
        .catch(() => {
            return "failed"
        })
    }

    const uploadedArrayHandler = () => {
        const uploadChannelString = contentInfo.uploaded_to_bc;

        const uploadChannelArray = uploadChannelString.split(",");
        return uploadChannelArray;
    }

    const uploadAllToBC = async () => {
        var result = true;
        const contentName = contentInfo.content_name;
        const contentID = contentInfo.content_unique_id;
        
        var offerArray = [];
        var uploadResult;
        var uploadNb = 0;

        const uploadedArray = uploadedArrayHandler();
        var uploadedString = uploadedArray.toString();

        for (let i = 0; i < cps.length; i++) {
            const cp = cps[i];
            const cpCode = getCpCode(cp.cpName);
            const contentCode  = getContentCode(contentID, cp.cpName);
            const checkUpload = uploadedString.includes(cp.cpName);
            if(checkUpload===false){
                let content = {
                    availableContentId : contentCode,
                    availableContentOwner : co.co_name,
                    availableContentName : contentName,
                    contentProvider: cpCode
                }
                uploadedString = cp.cpName + "," + uploadedString;
                uploadNb = uploadNb + 1;
                offerArray.push(uploadToBC(content));
            }
        }

       const allSuccess = Array(uploadNb).fill("uploaded");

        uploadResult = await Promise.all(offerArray);

        if(uploadResult.every((val, index) => val === allSuccess[index])){
            result = true
        } else {
            result = uploadResult.filter((result) => result !== "uploaded");
        }

        return {
            result : result,
            uploadedString : uploadedString
        }; 
    }

    const updateUploadStateHandler = async (contentId, uploadedString) => {
        const content = {
            contentId: contentId,
            uploaded: uploadedString
        }
        return updateUploadState(content).then(() => {
            return "updated";
        }).catch(() => {
            return "failed";
        })
    }

    const uploadToBC = async (content) => {
        return addContent(content).then(() => {
            return "uploaded"
        }).catch( (err) => {
            return err;
        })
    }

    const uploadAndSendToAllCp = async () => {
        var result = true;
        const priceInfoArray = contentPriceList.filter((array) => array.type===contentType);
        const contentPrice = priceInfoArray[0].contentPrice;
        const n7Reward = priceInfoArray[0].n7Reward;
        const maxN7Reward = contentPrice - n7Reward;
        const cpRewardValue = cpReward===0 ? Number((maxN7Reward/2).toFixed(2)) : cpReward;
        const volumeCommitment = 0;
        const availabilityStartDateValue = availabilityStartDate===null ? new Date(setUTCDate(new Date())).toISOString() : availabilityStartDate.toISOString();
        const availabilityEndDateValue = availabilityEndDate===null ? defaultAvailabilityEndDate().toISOString() : availabilityEndDate.toISOString();
        const accessDuration = accessDurationHandler();
        const contentID = contentInfo.content_unique_id;
        const contentOwner = contentInfo.content_owner;
        const receivedTime = new Date(setUTCDate(new Date())).toISOString();
        const uploadResult = await uploadAllToBC();

        var updateResult = "updated";
        var sendResult = "offer_sent";

        for (let i = 0; i < cps.length; i++) {
            var cp = cps[i];
            if(uploadResult.result===true){
                updateResult = await updateUploadStateHandler(contentInfo.content_unique_id, uploadResult.uploadedString);
            } else {
                result = false;
                setBcErrorOccured(true);
                setBcErr({
                    name: uploadResult.result[0].name,
                    msg: uploadResult.result[0].request.response
                })
            }

            if(uploadResult.result===true && updateResult==="updated"){
                let cpCoNegotiation = {
                    contentType: contentType,
                    contentPrice: contentPrice,
                    cpReward: cpRewardValue,
                    volumeCommitment: volumeCommitment,
                    availabilityStartDate: availabilityStartDateValue,
                    availabilityEndDate: availabilityEndDateValue,
                    accessDuration: accessDuration,
                    fromCpOrCo: "co",
                    contentID: contentID,
                    contentOwner: co.co_id,
                    contentProvider: cp.cpId,
                    receivedTime: receivedTime
                }
                sendResult = await sendOfferValid(cpCoNegotiation);
            } else {
                result = false;
            }
            
            if(sendResult!=="offer_sent"){
                result = false;
            } 
        } 
        return result; 
    }

    const createNewStreamHandler = async (coStream) => {
        return createNewStream(coStream).then(res => {
            if(res.status===202){
                return "created";
            } else {
                return "failed";
            }
        }).catch( (err) => {
            return err;
        })
    }

    const createNewStreamForAllCp = async () => {
        var result = true;
        var streamArray = [];

        var createdResult;
        var createdNb = 0;

        const foundContent = assets.find(asset => asset.filename.substr(0, asset.filename.lastIndexOf('.'))===contentInfo.content_name);
        
        const duration = accessDurationHandler();
        const durationArray = duration.split(" ");
        const days = durationArray[durationArray.length - 2];
        for (let i = 0; i < cps.length; i++) {
            const cp = cps[i];
            let streamInfo = {
                duration: days,
                timeUnit: "DAYS",
                startTime: new Date(setUTCDate(new Date())).toISOString(),
                fileName: foundContent.filename,
                streamName: contentInfo.content_unique_id + "_" + orgNameNoStyleHandler(cp.cpName)
            }
            createdNb = createdNb + 1;
            await new Promise(resolve => setTimeout(resolve, 1000)); // wait 1 s
            streamArray.push(createNewStreamHandler(streamInfo));
        }

        const allSuccess = Array(createdNb).fill("created");
        createdResult = await Promise.all(streamArray);

        if(createdResult.every((val, index) => val === allSuccess[index])){
            result = true
        } else {
            result = createdResult.filter((result) => result !== "created");
        }
        
        return result;
    }

    const sendNegotiation = () => {
        setShowLoader(true);
        uploadAndSendToAllCp().then(
            res => {
                if(res===true){
                    createNewStreamForAllCp()
                    .then(createdRes => {
                        setShowLoader(false);
                        if(createdRes===true){
                            alert(translator("proposal_sent"));
                            window.location.assign("/" + coHash +"/offers");
                        }
                    })
               }
            }
        )
    }
    
    if(contentInfo !== null){
        if(coNotExistError===true || contentInfo.length === 0){
            return <Error1 translator={translator} />
        } else if(bcErrorOccured){
            return <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
        } else {
            const sliderBorderColor = "0 0 0 2px " + deco.mainColor;
            const sliderShadowColor = "0 0 0 4px " + deco.mainColor;
            const priceInfoArray = contentPriceList.filter((array) => array.type===contentType);
            const n7Reward = priceInfoArray[0].n7Reward;
            const contentPrice = priceInfoArray[0].contentPrice;
            const markMax = Number((contentPrice - n7Reward).toFixed(2));
            const defauleValue = Number(markMax/2).toFixed(2);

            const sliderMark = {
                [cpReward]: {
                    style: {
                        color: deco.secondaryColor,
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500
                    }, 
                    label: cpReward + "€"
                }
            }
            
            const defaultSliderMark = {
                [defauleValue]: {
                    style: {
                        color: deco.secondaryColor,
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500
                    }, 
                    label: defauleValue + "€"
                }
            }

            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + coHash + "/myContents"}
                                contentName={contentInfo.content_name}
                            />
                            <Form form={form} name="send-proposal" className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_provider")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                {cpList.map((cp) => (
                                                    cps.some(item => JSON.stringify(item) === JSON.stringify({cpId: cp.value, cpName: cp.label})) ? selectedCp(cp) : unselectedCp(cp)
                                                ))}
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                {typeList.map((type) => (
                                                    type.value===contentType ? selectedType(type) : unselectedType(type)
                                                ))}
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("cp_reward")}
                                            </div>
                                            <div style={{width: "100%", display: "inline-flex", flexDirection: "row", gap: "8px"}}>
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>0.01€</div>
                                                <Form.Item noStyle name="cpReward" initialValue={cpReward===0 ? defauleValue : cpReward}>
                                                    <Slider
                                                        style={{width:"100%", margin: "auto", "--slider-border-color": sliderBorderColor, "--slider-shadow-color": sliderShadowColor}}
                                                        trackStyle={{ backgroundColor: deco.mainColor }}
                                                        marks={cpReward===0 ? defaultSliderMark : sliderMark}
                                                        min={0.01} max={markMax} step={0.01} value={cpReward===0 ? defauleValue : cpReward} onChange={cpRewardHandler}
                                                    />
                                                </Form.Item>
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>{markMax + "€"}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_start_date")}
                                            </div>
                                            <Form.Item
                                                name="startTime"
                                                style={{width: "100%"}}
                                                validateStatus={validStartTime().validateStatus}
                                                help={validStartTime().msg}
                                            >
                                                <DatePicker
                                                    placeholder={new Date().toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    format={dateFormat[localStorage.getItem("i18nextLng")]}
                                                    onChange={availabilityStartDateHandler} 
                                                    style={{"--date-default-color": deco.mainColor}}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_end_date")}
                                            </div>
                                            <Form.Item
                                                name="endTime"
                                                style={{width: "100%"}}
                                                validateStatus={validEndTime().validateStatus}
                                                help={validEndTime().msg}
                                            >
                                                <DatePicker
                                                    placeholder={defaultAvailabilityEndDate().toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    format={dateFormat[localStorage.getItem("i18nextLng")]}
                                                    onChange={availabilityEndDateHandler}
                                                    style={{"--date-default-color": deco.mainColor}}
                                                    />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {contentInfo.content_unique_id}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-button-panel">
                                    {validity()}
                                </div>             
                            </Form>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return (
            <LoadingPage translator={translator} />
        )
    }
}

export default SendOffer;