import axios from "axios";

const BASIC_URL="https://n7-demo-content-owner.nseven.xyz/api/v1/co";

// cp-co negotiation manip

const getAllCoOffers = (coHash) => {
    const url=BASIC_URL + "/cpCoNegotiations/" + coHash + "/all";
    return axios.get(url);
}

const getCoOffersByContent = (content) => {
    const url=BASIC_URL + "/cpCoNegotiations/content/" + content;
    return axios.get(url);
}

const sendOffer = (offer) => {
    const url=BASIC_URL + "/cpCoNegotiations";
    return axios.post(url, offer);
}

const getCpList = () => {
    const url=BASIC_URL + "/cpCoNegotiations/cpList";
    return axios.get(url);
}

const getCoOffer = (negotiationId) => {
    const url=BASIC_URL + "/cpCoNegotiations/id/" + negotiationId;
    return axios.get(url);
}

const cancelOffer = (offerId) => {
    const url=BASIC_URL + "/cpCoNegotiations/id/" + offerId + "/deleted";
    return axios.delete(url);
}

const acceptNegotiation = (negotiation) => {
    const url=BASIC_URL + "/cpCoNegotiations/accepted";
    return axios.put(url, negotiation);
}

// cp-sp negotiation manip
const getAllSpOffers = (coHash) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + coHash + "/all";
    return axios.get(url);
}

const getSuccessionNegotiationId = (cpSpNegotiationId) => {
    const url=BASIC_URL + "/cpSpNegotiations/coOffer/id/" + cpSpNegotiationId;
    return axios.get(url);
}

const getSps = (negotiationId) => {
    const url = BASIC_URL + "/cpSpNegotiations/sps/" + negotiationId;
    return axios.get(url);
}

const getSpDates = (negotiationId) => {
    const url = BASIC_URL + "/cpSpNegotiations/cpSpDates/" + negotiationId;
    return axios.get(url);
}

const getCpSpValidity = (negotiationId) => {
    const url = BASIC_URL + "/cpSpNegotiations/cpSpValidity/" + negotiationId;
    return axios.get(url);
}

export {getAllCoOffers, getCoOffersByContent, sendOffer, getCpList, getCoOffer, cancelOffer, acceptNegotiation, getAllSpOffers, getSuccessionNegotiationId, getSps, getSpDates, getCpSpValidity};
