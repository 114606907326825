import axios from "axios";
import getToken from "./GetToken";

const BASICURL="https://zgv7lgsq03.execute-api.us-east-1.amazonaws.com/v1_0/co";

const addFirstLoginUserToHF = (user, token) => {
    const specURL = "/cognito-user";
    var url = BASICURL + specURL;
    return axios.post(url, user, {
        headers: {
            'Authorization': token
        }
    })
}

const addContent = (content) => {
    const specURL="/broadcast/content";
    var url = BASICURL + specURL;
    return axios.post(url, content, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const getAvailableContent = (contentId, cp) => {
    const url = BASICURL + "/broadcast/" + cp + "/contents/" + contentId;
    return axios.get(url, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const getSuppliedContent = (contentId, cp) => {
    const url = BASICURL + "/contentAccess/" + cp + "/contents/" + contentId;
    return axios.get(url, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const getAllSuppliedContent = async (cp) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow', 
        headers: {
            'Authorization': getToken()
        }
    };
    
    const specURL = "/contentAccess/" + cp + "/contents";
    var url = BASICURL + specURL;
    var res = await fetch(url, requestOptions);
    return res.text();
}


export { addFirstLoginUserToHF, addContent, getAvailableContent, getSuppliedContent, getAllSuppliedContent};
