import React from "react";
import "../components/Error.css"

export default function Error1({translator}) {
    return(
        <div className="error-container">
            <div className="error-main-panel">
                <img className="error-img" src={require("../components/icons/error1.png")} />
                <div className="error-info-panel">
                    <div className="error-type">
                        {translator("error_404")}
                    </div>
                    <div className="error-msg-panel">
                        <div className="error-msg-title">
                            {translator("page_not_found")}
                        </div>
                        <div className="error-msg">
                            {translator("retry")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}