const coUserRoutes = [
    {
        id: 1,
        path: "/myContents",
        name: "my_contents"
    },
    {
        id: 2,
        path: "/offers",
        name: "offers"
    },
    {
        id: 3,
        path: "/diffusions",
        name: "diffusions"
    },
    {
        id: 4,
        path: "/revenu",
        name: "income"
    }
];
export default coUserRoutes;