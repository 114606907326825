import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import InfoPopUp from '../components/InfoPopUp';
import { showInfoPopUp, hideInfoPopUp } from '../components/PopUpManip';
import { getDiffusion } from '../myContents/ContentManip';
import { getSuppliedContent } from '../blockchainManip/BCManip';
import { calculateContainerHeight, calculateTableWidth } from '../components/ContainerDimensionManip';
import BCLoadingPage from '../components/BCLoadingPage';
import NoContent from '../components/NoContent';
import Error3 from '../errorManip/Error3';
import Error1 from '../errorManip/Error1';
import { getContentCode, getCpCode } from '../blockchainManip/CpCode';
import { getCoByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import coUserDecoRoutes from '../components/CoUserDecoRoutes';

const CheckViewership = ({translator, filters, coHash}) => {
    const [deco, setDeco] = useState(null);
    const [viewers, setViewers] = useState(null);
    const [content, setContent] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableProofWidth, setTableProofWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [coNotExistError, setCoNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const coData = await getCoByHash(coHash).then(res => res.data);
            if(coData!==undefined && coData.length!==0){
                setDeco(getDeco(coUserDecoRoutes, coData[0].co_name));
            } else {
                setCoNotExistError(true);
            }

            const url = window.location.href;
            const urlArray = url.split("/");
            const contentStateSpId = urlArray[urlArray.length - 2];
            const contentData = await getDiffusion(coHash, contentStateSpId).then(res => res.data);
            
            var viewersData = [];
            
            if(contentData.length !== 0){
                if(contentData[0].uploaded_to_bc===1){
                    const cpCode = getCpCode(contentData[0].content_provider_name);
                    const contentCode = getContentCode(contentData[0].content_unique_id, contentData[0].content_provider_name);
                    const suppliedContent = await getSuppliedContent(contentCode, cpCode).then(res => res.data).catch((err) => {
                        setBcErrorOccured(true);
                        setBcErr({name: err.name, msg: err.request.response});
                    });

                    if(suppliedContent !== undefined){
                        viewersData = suppliedContent.viewershipVerification;
                        viewersData = viewersData.filter(viewer => viewer.viewer !== "")
                        viewersData = completeViewerInfoArray(viewersData, contentData[0]);
                    }
                }
            }
            setContent(contentData.length===0 ? contentData : contentData[0]);
            setViewers(viewersData);

            const contentHeightValue = calculateContainerHeight();
            const tableViewerWidthValue = calculateTableWidth("table-viewer")===0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableProofWidthValue = calculateTableWidth("table-proof")===0 ? "auto" : calculateTableWidth("table-proof") + "px";
            if(contentHeightValue!==contentHeight || tableViewerWidthValue!==tableViewerWidth || tableProofWidthValue!==tableProofWidth){
                setContentHeight(contentHeightValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableProofWidth(tableProofWidthValue);
            }
        }

        init();
    }, []);

    const completeViewerInfoArray = (viewerArray, content) => {
        for (let i = 0; i < viewerArray.length; i++) {
            var viewer = viewerArray[i];
            viewer = {
                ...viewer,
                info: [
                    {
                        label: "identifiant",
                        value: content.content_unique_id
                    }
                ]
            }
            viewerArray[i] = viewer;
        }
        return viewerArray;
    }

    const proofVerifiedTag = () => {
        return(
            <div className="valid-tag table-proof">
                <div className="valid-tag-text">
                    {translator("proof_verified")}
                </div>
            </div>
        )
    }

    const proofNotVerifiedTag = () => {
        return(
            <div className="attention-tag table-proof">
                <div className="attention-tag-text">
                    {translator("proof_not_verified")}
                </div>
            </div>
        )
    }

    if(content!==null && viewers!==null){
        if(content.length===0 || coNotExistError){
            return <Error1 translator={translator} />
        } else if(bcErrorOccured){
            return <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
        } else if(viewers.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + coHash + "/diffusions"} 
                                contentName={content.content_name} 
                                co={content.content_owner_name}
                                cp={content.content_provider_name} 
                                sp={content.service_provider_name}
                            />
                            <div className="table-container" style={{ maxHeight: contentHeight }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}>{translator("viewer")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableProofWidth}}>{translator("proof")}</th>         
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewers.map((viewer, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p className="table-viewer" style={{ margin: 0, width: "max-content" }}>{viewer.viewer}</p></td>
                                                <td>{viewer.validation==="valid" ? proofVerifiedTag() : proofNotVerifiedTag()}</td>
                                                <td className="table-infomation-panel" style={{ paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={viewer.info}/> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={() => {showInfoPopUp(id)}} onMouseLeave={() => {hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(viewers.length===0){
            return(
                 <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + coHash + "/diffusions"} 
                                contentName={content.content_name} 
                                co={content.content_owner_name}
                                cp={content.content_provider_name} 
                                sp={content.service_provider_name}
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return (
            <BCLoadingPage translator={translator} />
        )
    }
}

export default CheckViewership;
